import React from 'react';
import images from '../../assets/img';
// eslint-disable-next-line no-unused-vars
import { POPUPS } from '../../constants/game';
import { useTranslation } from '../../context/LanguageProvider';

const { leaderValidity } = images;

const MainHeadLeft = ({ toggleHandler, roundId }) => {
  const t = useTranslation();
  return (
    <div className="main__head-left">
      <a className="validity__link" onClick={() => toggleHandler(POPUPS.VALIDITY)}>
        <span className="validity__link-icon">
          <img src={leaderValidity} alt="Validity Icon" className="validity-icon-svg" />
        </span>
        <span className="validity__link-text">{t('Provable Fairness')}</span>
      </a>
      <span className="validity__id">
        {t('Draw ID:')} {roundId}
      </span>
    </div>
  );
};
export default MainHeadLeft;
