import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { forMap } from '../../../helpers/general';
import BurgerMenuItems from './BurgerMenuItems';
import { BurgerTabsName } from '../../../constants/game';
import ProvablyFair from './ProvablyFair';
import GameRules from './GameRules';
import GameLimits from './GameLimits';
import { useTranslation } from '../../../context/LanguageProvider';

function SettingsBody({
  handleIsActive,
  handleActiveBurgerTab,
  activeBurgerTab,
  seedPopupChangeReducer,
  userInfo,
}) {
  const outSideClick = (evt) => {
    if (!seedPopupChangeReducer) {
      const flyOutElement = document.querySelector('.settings__body');
      let targetElement = evt.target;
      do {
        if (targetElement === flyOutElement) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      handleIsActive();
    }
  };

  const handleClick = (event) => {
    outSideClick(event);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const t = useTranslation();

  return (
    <div className="settings__body">
      <ul className="settings__list">
        {forMap(3, (index) => (
          <BurgerMenuItems
            key={index}
            activeBurgerTab={activeBurgerTab}
            id={index}
            name={t(BurgerTabsName[index])}
            handleActiveBurgerTab={handleActiveBurgerTab}
          />
        ))}
      </ul>
      <ul className="settings__row-list">
        {activeBurgerTab === 0 && <ProvablyFair />}
        {activeBurgerTab === 1 && <GameRules userInfo={userInfo} />}
        {activeBurgerTab === 2 && <GameLimits userInfo={userInfo} />}
      </ul>
    </div>
  );
}

const mapStateToProps = ({ seedPopupChangeReducer, userInfoReducer }) => ({
  seedPopupChangeReducer,
  userInfo: userInfoReducer,
});

export default connect(mapStateToProps)(SettingsBody);
