import React, { useEffect } from 'react';
import AvatarsItem from './AvatarsItem';
import { forMap } from '../../../../helpers/general';

function AvatarsPopup({
  isActive, closePopup, outSideClick, setIsActive, selectedAvatar,
}) {
  const handleClick = (event) => {
    outSideClick(event, 'avatars');
  };

  useEffect(() => {
    setIsActive(true);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ handleClick, setIsActive ]);

  return (
    <div className={`popup-avatars ${isActive ? 'active' : ''}`}>
      <div className='popup-avatars__top'>
        <button
          type='button'
          className='popup-avatars__button'
          onClick={closePopup}
        >
          <span className='popup-avatars__button-line' />
        </button>
      </div>
      <div className='popup-avatars__list'>
        {forMap(6, index => <AvatarsItem
          key={index}
          id={index + 1}
          selectedAvatar={selectedAvatar}
          closePopup={closePopup}
        />)}
      </div>
    </div>
  );
}

export default AvatarsPopup;
