import React, { useEffect } from 'react';
import RandomInput from './RandomInput';
import ManualInput from './ManualInput';
import NextServerSeed from './NextServerSeed';
import ProvablyFairInfo from './ProvablyFairInfo';

function ProvablyFair() {
  return (
    <li className='settings__row-item'>
      <ProvablyFairInfo />
      <div className='settings__hash'>
        <RandomInput />
        <ManualInput />
      </div>
      <NextServerSeed />
    </li>
  );
}

export default ProvablyFair;
