import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../context/LanguageProvider';

function GameLimits({ userInfo }) {
  const { minBet, maxBet, maxWin, currency } = userInfo;

  const t = useTranslation();
  const language = useSelector((state) => state.activeLanguageReducer);

  return (
    <div style={{ padding: '0.7rem' }}>
      {language === 'am' ? (
        <div className="provably-fair__center-text">
          {t('The Gamer')}
          <span className="provably-fair__answer" style={{ marginLeft: '5px' }}>
            "{t('Add Ticket')}"{' '}
          </span>
          {t('can place 2 bets simultaneously during a single round by clicking on')}
        </div>
      ) : (
        <div className="provably-fair__center-text">
          {t('The Gamer can place')} <span className="provably-fair__answer">2 {t('bets')}</span>{' '}
          {t('simultaneously during a single round by clicking on')}
          <span className="provably-fair__answer" style={{ marginLeft: '5px' }}>
            "{t('Add Ticket')}".
          </span>
        </div>
      )}
      <br />
      <div className="provably-fair__center-text">
        <span className="provably-fair__answer">{t('Minimum BET')}</span>{' '}
        <span className="game-rules-green">
          {minBet} {currency}
        </span>
        <br />
        <span className="provably-fair__answer">{t('Maximum BET')}</span>{' '}
        <span className="game-rules-green">
          {maxBet} {currency}
        </span>
        <br />
        <span className="provably-fair__answer">{t('Maximum WIN')}</span>{' '}
        <span className="game-rules-green">
          {maxWin} {currency}
        </span>
        <br />
        <br />
        {t('Maximum Win per round and per player is')}{' '}
        <span className="game-rules-green">
          {' '}
          {maxWin} {currency}.
        </span>
        <br />
        {t("Crazy Ball's coefficient is not determined and varies from")}
        <span className="provably-fair__answer" style={{ marginLeft: '5px' }}>
          1.00 {t('to infinity')}.
        </span>
      </div>
    </div>
  );
}

export default GameLimits;
