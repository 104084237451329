import React from 'react';
import { SVGIMG } from '../../../constants/game';
import { connect } from 'react-redux';
import { useTranslation } from '../../../context/LanguageProvider';

function NextServerSeed({ serverSeed }) {
  const { sha256_server_seed: nextSeed } = serverSeed;
  const t = useTranslation();

  return (
    <>
      <div className="settings__server">
        <div className="settings__server-left">{SVGIMG[3]}</div>
        <div className="settings__server-right">
          <div className="settings__server-text">{t('Next Server Seed')}</div>
          <div className="settings__server-text--bold">SHA256</div>
        </div>
      </div>
      <div className="settings__server-name">{nextSeed}</div>
      <div className="settings__server-bottom">
        {t('You can check fairness of each bet from bets history')}
      </div>
    </>
  );
}
const mapStateToProps = ({ serverSeedReducer }) => ({ serverSeed: serverSeedReducer });

export default connect(mapStateToProps)(NextServerSeed);
