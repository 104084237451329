import React from 'react';
import { SVGIMG } from '../../../constants/game';
import { useTranslation } from '../../../context/LanguageProvider';

function ProvablyFairInfo() {
  const t = useTranslation();

  return (
    <div className="settings__main">
      <div className="provably-fair">
        <div className="provably-fair__title">{t('Provable Fairness Settings')}</div>
        <div className="provably-fair__question">{t('What is Provable Fairness?')}</div>
        <div className="provably-fair__answer">
          {t(
            'This game uses Provable Fairness technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.',
          )}
        </div>
        <div className="provably-fair__center">
          <div className="provably-fair__center-left">{SVGIMG[1]}</div>
          <div className="provably-fair__center-right">
            <div className="provably-fair__center-title">{t('Next Client (your) side:')}</div>
            <div className="provably-fair__center-text">
              {t(
                'Round result is determined form combination of server seed and first 3 bets of the round.',
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProvablyFairInfo;
