import React from 'react';
import PropTypes from 'prop-types';
import { MenuItems } from '../../../constants/game';
import { useTranslation } from '../../../context/LanguageProvider';
import { useSelector } from 'react-redux';

MenuItem.propTypes = {
  activeTab: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  handleActiveTab: PropTypes.func.isRequired,
};

function MenuItem({ activeTab, id, handleActiveTab }) {
  const language = useSelector((state) => state.activeLanguageReducer);
  const t = useTranslation();

  return (
    <li className="menu__item" onClick={() => handleActiveTab(id)}>
      <a className={`menu__link ${activeTab === id ? 'active' : ''}`}>
        <span className="menu__icon">
          <span className={`menu__icon-${MenuItems[id].section}`}>{MenuItems[id].svg}</span>
        </span>
        <span style={{ fontSize: language === 'sw' ? 12 : 14 }}>{t(MenuItems[id].name)}</span>
      </a>
    </li>
  );
}

export default MenuItem;
