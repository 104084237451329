import React from 'react';
import PropTypes from 'prop-types';
import images from '../../../assets/img';
import MenuItem from './MenuItem';
import { forMap } from '../../../helpers/general';

LeftHeader.propTypes = {
  activeTab: PropTypes.number.isRequired,
  handleActiveTab: PropTypes.func.isRequired,
};

function LeftHeader({ handleActiveTab, activeTab }) {
  return (
    <header className='header'>
      <div className='header__logo'>
        <a className='header__logo-link'><img src={images.logo} alt='Logo' className='header__logo-img' /></a>
      </div>
      <div className='header__menu'>
        <ul className='menu__list'>
          {forMap(3, index => <MenuItem
            key={index}
            id={index}
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            />)}
        </ul>
      </div>
    </header>
  );
}

export default LeftHeader;
