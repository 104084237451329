import Picker from 'react-giphy-component';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { setChatNewMessage } from '../../../../Home';

function ChatGIF({
  setWriteGIF, writeGIF, avatar, rateLimit, setRateLimit,
}) {
  const sendGIF = (gif) => {
    const clearUrl = gif.preview_gif.url.split('media/')[1];
    const gifName = clearUrl.slice(0, clearUrl.indexOf('/'));
    setChatNewMessage({ type: 2, content: gifName, avatar });
    setWriteGIF(!writeGIF);
    setRateLimit(false);
    setTimeout(() => { setRateLimit(true); }, 1000);
  };

  return (
    <Picker
      className='jVXKvl gERqLf'
      onSelected={rateLimit && sendGIF}
      apiKey='RzyjgK4sl52CFuGmklsGSD44RHIc5WxC'
    />
  );
}

export default ChatGIF;
