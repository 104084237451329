import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { CONSOLE_TYPES } from '../../../../../constants/game';
import { useTranslation } from '../../../../../context/LanguageProvider';

const { SECOND } = CONSOLE_TYPES;

const AddTicket = ({ toggleHandler, betResponse, isBetActive, isActive }) => {
  const {
    is_accepted: isAccepted,
    is_canceled: isCanceled,
    is_won: isWonTicket,
  } = betResponse[SECOND];
  const isClickedButton =
    isAccepted || isBetActive || isActive === false || (isCanceled === false && !isWonTicket);

  const language = useSelector((state) => state.activeLanguageReducer);
  const t = useTranslation();

  return (
    <div
      className="console__settings-right"
      style={isClickedButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}
      onClick={toggleHandler}>
      <button className="add-ticket__button" />
      <div className="add-ticket__text">
        <span className="add-ticket__text-add" style={{ fontSize: language === 'sw' ? 12 : 14 }}>
          {t('Add Ticket')}
        </span>
        <span className="add-ticket__text-delete">{t('Delete')}</span>
      </div>
    </div>
  );
};

const mapStateToProps = ({ betResponseReducer }) => ({ betResponse: betResponseReducer });

export default connect(mapStateToProps)(AddTicket);
