import React from 'react';
import { useTranslation } from '../../../context/LanguageProvider';
import { useSelector } from 'react-redux';

function GameRules({ userInfo }) {
  const { maxWin, currency } = userInfo;
  const language = useSelector((state) => state.activeLanguageReducer);
  const t = useTranslation();

  return (
    <div style={{ padding: '0.7rem' }}>
      <div className="provably-fair__center-text">
        {language !== 'am'
          ? t(
              'Crazy Ball is a new game allowing Gamers to place bets easily with one click and win up to',
            )
          : t('Crazy Ball  is a new game allowing Gamers to place bets easily')}{' '}
        <span className="game-rules-green">
          {maxWin} {currency}.
        </span>
        {language === 'am' && (
          <div className="provably-fair__center-text">{t('with one click and win up to')}</div>
        )}
      </div>
      <div className="provably-fair__title">{t('HOW TO PLAY')}</div>
      {language !== 'am' && (
        <div className="provably-fair__center-text">
          {t(
            'The Ball starts to fly and the coefficient begins to grow in parallel․ The Gamer needs to place a',
          )}{' '}
          <span className="provably-fair__answer">{t('BET(s)')}</span>{' '}
          {t(
            'before the round starts, otherwise, the placed bets during the started round will participate for the next round.',
          )}
        </div>
      )}
      <div className="provably-fair__center-text">
        {language === 'am'
          ? t(
              'The Ball starts to fly and the coefficient begins to grow in parallel. The Gamer needs to place a BET before the round starts, otherwise, the placed bets during the started round will participate for the next round. Once the round started the Gamer should click on the',
            )
          : t('Once the round started the Gamer should click on the')}
        <span className="provably-fair__answer"> "{t('CASHOUT')}"</span>{' '}
        {t('button whenever wanted but before the round finishes.')}
      </div>
      <div className="provably-fair__center-text">
        {t('The Gamer can use')}
        <span className="provably-fair__answer" style={{ marginLeft: '5px' }}>
          "{t('Auto Bet')}"
        </span>{' '}
        {t('and/or')}
        <span className="provably-fair__answer" style={{ marginLeft: '5px' }}>
          "{t('Auto Cashout')}"{' '}
        </span>{' '}
        {t(
          'features to place the same amount bets and make the same amount cashouts automatically until switching off the auto modes.',
        )}
      </div>
      <br />
      <div className="provably-fair__center-text">
        <span className="provably-fair__answer">{t('The Gamer wins')}</span>{' '}
        {t(
          'in case the Cashout is done before the Ball disappears and the coefficient stops to grow.',
        )}
      </div>
      <div className="provably-fair__center-text">
        {language !== 'am' && (
          <span className="provably-fair__answer">{t('The Gamer loses')} </span>
        )}

        {t('if the Cashout has not been done before the round finishes.')}
        {language === 'am' && (
          <span className="provably-fair__answer"> {t('The Gamer loses')}</span>
        )}
      </div>
      <br />
      <div className="provably-fair__center-text">
        {t(
          "The winning amount is calculated by multiplying the coefficient by the bet's amount. After Cashout, the won amount will be reflected on the Gamer's balance instantly.",
        )}
      </div>
    </div>
  );
}

export default GameRules;
