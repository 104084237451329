import React, { useState, useEffect, useRef } from 'react';
import CoefficientList from './CoefficientList';
import Chat from './Chat/Chat';

const MainBottom = ({
  toggleHandler, isOpenChat, setIsOpenChat,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const chatHandler = () => {
    setIsOpenChat(!isOpenChat);
  };

  const outSideClick = (evt) => {
    const flyOutElement = document.querySelector('.coefficient');
    let targetElement = evt.target;
    do {
      if (targetElement === flyOutElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    setIsOpen(false);
  };
  useEffect(() => {
    document.addEventListener('click', outSideClick);
  }, []);
  return (
    <div className='main__bottom'>
      <div className='coefficient'>
        <CoefficientList setIsOpen={setIsOpen} isOpen={isOpen} toggleHandler={toggleHandler} />
      </div>
      <Chat
        isOpenChat={isOpenChat}
        chatHandler={chatHandler}
        toggleHandler={toggleHandler}
      />
    </div>
  );
};
export default React.memo(MainBottom);
