import React from 'react';
import { connect } from 'react-redux';
import avatars from '../../../../assets/img/avatars/avatars';
import { POPUPS } from '../../../../constants/game';

const UserInfo = ({ popupToggleHandler, avatarReducer, balance, userInfo }) => {
  const { id, avatar, currency, minBet, maxWin } = userInfo;
  const balancePercent = (balance * 100) / (maxWin - minBet);

  return (
    <div className="console__bottom-field">
      <div className="console__bottom">
        <div
          className="popup-avatars__item selected console__avatar"
          onClick={() => popupToggleHandler(POPUPS.AVATARS, avatarReducer)}>
          <div className="popup-avatars__photo ">
            <img src={avatars[avatar]} alt="avatar" className="avatars-img" />
          </div>
        </div>
        <div className="console__bottom-id">ID: {id}</div>
        <div className="console__bottom-price">
          {balance} {currency}
        </div>
        <div className="console__bottom-steps">
          <div className="console__bottom-steps-active" style={{ width: `${balancePercent}%` }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ balanceReducer: { balance }, avatarReducer, userInfoReducer }) => ({
  balance,
  avatarReducer,
  userInfo: userInfoReducer,
});

export default connect(mapStateToProps)(UserInfo);
