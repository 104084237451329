import React from 'react';
import { connect } from 'react-redux';
import ChatHeader from './ChatHeader';
// eslint-disable-next-line import/no-cycle
import ChatBody from './ChatBody';

function Chat({
  userInfoReducer, chatHandler, isOpenChat, toggleHandler, usersOnlineCount, gameState,
}) {
  const { round_id: currentRoundID } = gameState;
  return (
    <div className={`chat ${isOpenChat ? 'open' : ''}`}>
      <ChatHeader chatHandler={chatHandler} usersOnlineCount={usersOnlineCount} />
      <ChatBody
        isOpenChat={isOpenChat}
        avatar={userInfoReducer.avatar}
        userId={userInfoReducer.id}
        toggleHandler={toggleHandler}
        currentRoundID={currentRoundID}
      />
    </div>
  );
}

const mapStateToProps = ({ userInfoReducer, usersOnlineCount, gameStateReducer }) => ({
  userInfoReducer, usersOnlineCount, gameState: gameStateReducer,
});

export default connect(mapStateToProps)(Chat);
