import rocketCrash from './rocketCrash.mp3';
import rocketBet from './rocketBet.mp3';
import rocketStart from './rocketStart.wav';
import rocketMusic from './rocketMusic.mp3';

const sounds = {
  rocketCrash,
  rocketBet,
  rocketStart,
  rocketMusic,
};

export default sounds;
