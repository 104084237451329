import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from '../../../../../context/LanguageProvider';
import { setNotifications, setSoundData } from '../../../../../redux/actions/gameActions';

function Settings({
  autoCashOutOdd,
  setAutoCashOutOdd,
  setIsBetActive,
  isAutoGameActive,
  setIsAutoGameActive,
  // eslint-disable-next-line no-shadow
  type,
  betResponse,
  isBetActive,
  setNotifications,
  soundDataReducer,
  setSoundData,
  isActive,
  userInfoReducer: userInfo,
  canAddTicket,
}) {
  const [disabled, setDisabled] = useState(false);
  const inputEl = useRef(null);
  const { maxWin, minBet } = userInfo;
  const lengthAccepted = `${maxWin / minBet}`.length;
  const language = useSelector((state) => state.activeLanguageReducer);

  const {
    is_accepted: isAccepted,
    is_canceled: isCanceled,
    is_won: isWonTicket,
    auto_cash_out_odd: betAutoCashOutOdd,
  } = betResponse[type];

  useEffect(() => {
    // eslint-disable-next-line camelcase
    betResponse[type]?.auto_cash_out_odd &&
      setAutoCashOutOdd({ active: true, odd: betAutoCashOutOdd.toFixed(2) });
  }, [betResponse]);

  const cashOutOddHandle = ({ target: { value, name } }) => {
    if (name === 'active') {
      value = !autoCashOutOdd.active;
      setAutoCashOutOdd({ ...autoCashOutOdd, [name]: value });
    } else {
      const regex = /^[0-9]{0,9}?\d*\.?\d*$/;
      const valid = regex.test(value);
      if (
        !valid ||
        value[0] === '0' ||
        value[0] === '.' ||
        value.split('.')[0].length > lengthAccepted ||
        (value.split('.').length > 1 && value.split('.')[1].length > 2)
      )
        return;
      setAutoCashOutOdd({ ...autoCashOutOdd, [name]: value });
    }
  };
  const validateInput = () => {
    if (autoCashOutOdd.odd < 1.01) {
      setAutoCashOutOdd({ ...autoCashOutOdd, odd: isAutoGameActive ? 1.1 : 1.01 });
      setNotifications({ [type]: { active: true, content: `${t('Min Auto Cashout is 1.01x')}` } });
    } else {
      setAutoCashOutOdd({ ...autoCashOutOdd, odd: Number(autoCashOutOdd.odd).toFixed(2) });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputEl.current.blur();
    }
  };

  useEffect(() => {
    setIsBetActive(isAutoGameActive);
  }, [isAutoGameActive]);
  const isClickedButton =
    isAccepted || isBetActive || !isActive || (isCanceled === false && !isWonTicket);

  const t = useTranslation();

  return (
    <div className="console__settings-left">
      <div className="console-switchers">
        <div className={'console-switchers__auto active'}>
          <div className="console-switchers-list">
            <div
              className="console-switchers-list"
              style={
                isClickedButton && !isAutoGameActive
                  ? {
                      opacity: '0.5',
                      pointerEvents: 'none',
                    }
                  : {}
              }>
              <div className="switch__title" style={{ fontSize: language === 'sw' ? 12 : 14 }}>
                {t('Auto Bet')}
              </div>
              <label className="switch__label">
                <input
                  disabled={disabled || !canAddTicket}
                  type="checkbox"
                  className="switch__input"
                  onChange={autoGameHandle}
                  checked={isAutoGameActive}
                />
                <span className="switch__slider" />
              </label>
            </div>
            <div
              className="console-switchers-list"
              style={isClickedButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}>
              <div
                className="switch__title"
                style={{
                  fontSize: language === 'sw' ? 12 : 14,
                  marginLeft: language === 'sw' ? -4 : 0,
                }}>
                {t('Auto Cashout')}
              </div>
              <label className="switch__label">
                <input
                  type="checkbox"
                  className="switch__input switch__input-qashout"
                  name="active"
                  onChange={cashOutOddHandle}
                  checked={autoCashOutOdd.active}
                />
                <span className="switch__slider" />
                <input
                  autoComplete="off"
                  ref={inputEl}
                  onKeyPress={handleKeyPress}
                  onBlur={validateInput}
                  type="text"
                  name="odd"
                  className="switch__price-input"
                  value={autoCashOutOdd.odd}
                  onChange={cashOutOddHandle}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function autoGameHandle() {
    if (autoCashOutOdd.odd < 1.1) {
      setAutoCashOutOdd({ ...autoCashOutOdd, odd: 1.1 });
      setNotifications({ [type]: { active: true, content: `${t('Min Auto Cashout is 1.1x')}` } });
    }
    setDisabled(true);
    setIsAutoGameActive(!isAutoGameActive);
    setSoundData({ betButton: !soundDataReducer.betButton });
    setTimeout(() => {
      setDisabled(false);
    }, 700);
  }
}

const mapStateToProps = ({ betResponseReducer, soundDataReducer, userInfoReducer }) => ({
  betResponse: betResponseReducer,
  soundDataReducer,
  userInfoReducer,
});

const mapDispatchToProps = {
  setNotifications,
  setSoundData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
