import React, { useState, useEffect } from 'react';

const autoCashOutBet = 5;

const ConsoleField =
  (CurrentConsole) =>
  ({ isSecondTicketActive, toggleHandler, userInfo, canAddTicket, ...props }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [autoCashOutOdd, setAutoCashOutOdd] = useState({
      active: false,
      odd: autoCashOutBet.toFixed(2),
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [amount, setAmount] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isFocused, setIsFocused] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [step, setStep] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isBetActive, setIsBetActive] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isAutoGameActive, setIsAutoGameActive] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      userInfo.minBet && setAmount(Number(userInfo.default_bet_amount));
    }, [userInfo.minBet]);
    return (
      <CurrentConsole
        {...props}
        amount={amount}
        setAmount={setAmount}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        isSecondTicketActive={isSecondTicketActive}
        toggleHandler={toggleHandler}
        autoCashOutOdd={autoCashOutOdd}
        setAutoCashOutOdd={setAutoCashOutOdd}
        setStep={setStep}
        isBetActive={isBetActive}
        setIsBetActive={setIsBetActive}
        isAutoGameActive={isAutoGameActive}
        setIsAutoGameActive={setIsAutoGameActive}
        canAddTicket={canAddTicket}
      />
    );
  };
export default ConsoleField;
