import React from 'react';
import { useTranslation } from '../../../../context/LanguageProvider';

function LeaderHeader() {
  const t = useTranslation();

  return (
    <div className="leaders-head">
      <div className="leaders-head__gamers leaders__gamers--width">
        <div className="leaders-head__gamers-text">{t('GAMERS')}</div>
      </div>
      <div className="leaders-head__bet leaders__bet--width">{t('BET')}</div>
      <div className="leaders-head__odd leaders__odd--width">X</div>
      <div className="leaders-head__win leaders__win--width">{t('WIN')}</div>
      <div className="leaders-head__icons leaders__icons--width" />
    </div>
  );
}

export default LeaderHeader;
