import React, { useEffect, useState } from 'react';
import ConsoleField from './ConsoleFields/ConsoleField';
import Amount from './ConsoleFields/Amount';
import AddTicket from './ConsoleFields/AddTicket';
import Settings from './ConsoleFields/Settings';
import BetButton from './ConsoleFields/BetButton';
import ConsoleTop from './ConsoleFields/ConsoleTop';
import { changeInput } from '../../../../helpers/general';
import { CONSOLE_TYPES } from '../../../../constants/game';

const { SECOND } = CONSOLE_TYPES;

const SecondConsole = ({
  toggleHandler, amount, setAmount, autoCashOutOdd, setAutoCashOutOdd,
  isFocused, setIsFocused, setStep, isBetActive, setIsBetActive, isAutoGameActive,
  setIsAutoGameActive, canAddTicket, noteData, setNotifications,
}) => {
  const [ isActive, setActive ] = useState(true);
  // eslint-disable-next-line no-nested-ternary
  const styleName = noteData.content?.indexOf('You won') >= 0 ? 'win' : noteData.content?.indexOf('Game Over!') >= 0 ? 'loss' : '';

  useEffect(() => {
    changeInput(amount, SECOND, isFocused);
  }, [ amount, isFocused ]);

  useEffect(() => {
    noteData.active && setTimeout(() => {
      setNotifications({
        second: {
          active: false,
          content: null,
        },
      });
    }, 3000);
  }, [ noteData ]);

  return (
    <div className={`console console-second active ${autoCashOutOdd.active ? 'console-qashout' : ''}`}>
      <div className='console__settings'>
        <ConsoleTop isBetActive={isBetActive} type={SECOND} />
        <Amount
          amount={amount}
          setAmount={setAmount}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          setStep={setStep}
          isBetActive={isBetActive}
          type={SECOND}
          isActive={isActive}
        />
        <Settings
          setAutoCashOutOdd={setAutoCashOutOdd}
          autoCashOutOdd={autoCashOutOdd}
          setIsBetActive={setIsBetActive}
          isAutoGameActive={isAutoGameActive}
          setIsAutoGameActive={setIsAutoGameActive}
          isBetActive={isBetActive}
          type={SECOND}
          isActive={isActive}
          canAddTicket={canAddTicket}
        />
        <BetButton
          amount={amount}
          autoCashOutOdd={autoCashOutOdd}
          isBetActive={isBetActive}
          setIsBetActive={setIsBetActive}
          isAutoGameActive={isAutoGameActive}
          setIsAutoGameActive={setIsAutoGameActive}
          type={SECOND}
          isActive={isActive}
          setActive={setActive}
          canAddTicket={canAddTicket}
        />
        <AddTicket toggleHandler={toggleHandler} isBetActive={isBetActive} isActive={isActive} />
      </div>
      <div className={`console__notification ${styleName} ${noteData.active ? '' : 'active'}`}>{noteData.content}</div>
    </div>
  );
};
export default ConsoleField(SecondConsole);
