import React from 'react';
import { useTranslation } from '../../../../../../context/LanguageProvider';

const ClientSeedTop = () => {
  const t = useTranslation();

  return (
    <div className="provably-fair__center">
      <div className="provably-fair__center-left">
        <svg
          className="provably-fair__laptop"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42.9 28.17">
          <path d="M42.84,24a.21.21,0,0,0-.16-.08H.22A.21.21,0,0,0,.05,24a.21.21,0,0,0,0,.17s.65,4,5.62,4H37.28c5,0,5.61-3.93,5.61-4A.21.21,0,0,0,42.84,24ZM24.64,26.8H18.25V25.33h6.39Z" />
          <path d="M4.63,22.42H38.27a.88.88,0,0,0,.88-.88V.88A.88.88,0,0,0,38.27,0H4.63a.87.87,0,0,0-.88.88V21.54A.87.87,0,0,0,4.63,22.42ZM6.83,3.08H36.06V19.34H6.83Z" />
        </svg>
      </div>
      <div className="provably-fair__center-right">
        <div className="provably-fair__center-title">{t('Client Seed:')}</div>
        <div className="provably-fair__center-text">{t('Generated on Players Seed')}</div>
      </div>
    </div>
  );
};
export default ClientSeedTop;
