import {
  // eslint-disable-next-line import/named
  dataTypes,
  seedPopup,
  seedTypes,
  soundDataTypes,
  optionsType,
} from '../actionTypes';

const {
  SET_ROUNDS_HISTORY,
  SET_GAME_STATE,
  SET_LEADERS_LIST,
  SET_MY_BETS_LIST,
  UPDATE_MY_BETS_LIST,
  SET_PREVIOUS_GAME,
  SET_BALANCE,
  SET_USER_INFO,
  UPDATE_CHAT_HISTORY,
  UPDATE_LIKES,
  SET_ERRORS_DATA,
  SET_NOTIFICATIONS_DATA,
  SET_ONLINE_USERS_COUNT,
  SET_CHAT_HISTORY,
  SET_ACTIVE_LANGUAGE,
} = dataTypes;

const { SET_SOUND_DATA } = soundDataTypes;

const { SET_OPTIONS } = optionsType;

const { SET_SEED_POPUP_CHANGE } = seedPopup;

const { SET_RANDOM_SEED, SET_IS_ACTIVE_MANUAL_SEED, SET_MANUAL_SEED } = seedTypes;

export const setRoundsHistory = (value) => ({ type: SET_ROUNDS_HISTORY, value });

export const setGameState = (value) => ({ type: SET_GAME_STATE, value });

export const setSeedPopupChange = (value) => ({ type: SET_SEED_POPUP_CHANGE, value });

export const setRandomSeed = (value) => ({ type: SET_RANDOM_SEED, value });

export const setManualSeed = (value) => ({ type: SET_MANUAL_SEED, value });

export const setIsActiveManualSeed = (value) => ({ type: SET_IS_ACTIVE_MANUAL_SEED, value });

export const setLeadersList = (value) => ({ type: SET_LEADERS_LIST, value });

export const setMyBetsList = (value) => ({ type: SET_MY_BETS_LIST, value });

export const updateMyBetsList = (value) => ({ type: UPDATE_MY_BETS_LIST, value });

export const setChatHistory = (value) => ({ type: SET_CHAT_HISTORY, value });

export const updateChatHistory = (value) => ({ type: UPDATE_CHAT_HISTORY, value });

export const updateChatLikes = (value) => ({ type: UPDATE_LIKES, value });

export const setPreviousGame = (value) => ({ type: SET_PREVIOUS_GAME, value });

export const setBalance = (value) => ({ type: SET_BALANCE, value });

export const setUserInfo = (value) => ({ type: SET_USER_INFO, value });

export const setSoundData = (value) => ({ type: SET_SOUND_DATA, value });

export const setErrors = (value) => ({ type: SET_ERRORS_DATA, value });

export const setNotifications = (value) => ({ type: SET_NOTIFICATIONS_DATA, value });

export const setOptions = (value) => ({ type: SET_OPTIONS, value });

export const setOnlineUsersCount = (value) => ({ type: SET_ONLINE_USERS_COUNT, value });

export const setActiveLanguage = (value) => ({ type: SET_ACTIVE_LANGUAGE, value });
