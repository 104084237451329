import React from 'react';
import avatars from '../../../../../assets/img/avatars/avatars';
import { generateOddColor } from '../../../../../helpers/generateOddColor';

const PreviousGameItem = ({ item, crashValue }) => {
  const {
    avatar, nickname, amount, cashout_odd: cashoutOdd, cashout_amount: cashoutAmount,
  } = item;
  const number = cashoutOdd ? generateOddColor(cashoutOdd) : 'loss';
  const color = cashoutOdd ? 'normal' : 'loss-color';

  return (
    <div className='game__item game__item--light'>
      <div className='game__gamers game__gamers--width'>
        <div className='game__gamers-photo'>
          <img src={avatars[avatar]} alt='avatar' className='avatars-img' />
        </div>
        <div className='game__gamers-number '>{nickname}</div>
      </div>
      <div className='game__bet game__bet--width'>
        {amount}
      </div>
      <div className={`game__odd game__odd--width wcolor-${number}`}>
        {cashoutOdd || crashValue}x
      </div>
      <div className={`game__win game__win--width wcolor-${color}`}>
        {cashoutAmount ? cashoutAmount.toFixed(2) : '- - -'}
      </div>
    </div>
  );
};
export default PreviousGameItem;
