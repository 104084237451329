import React, { useState, useCallback } from 'react';
import MainHeadRight from './MainHeadRight/MainHeadRight';
import MainBottom from './MainBottom/MainBottom';
import MainHeadLeft from './MainHeadLeft';
import MainMiddle from './MainMiddle/MainMiddle';
import { useTranslation } from '../../context/LanguageProvider';

function Main({ toggleHandler, activePopups, roundId, defaultOptions, startTime, setStartTime }) {
  const [isOpenChat, setIsOpenChat] = useState(false);

  const t = useTranslation();
  return (
    <div className="main">
      <div className="main__head">
        <MainHeadLeft toggleHandler={toggleHandler} roundId={roundId} />
        <MainHeadRight defaultOptions={defaultOptions} />
      </div>
      <MainMiddle
        activePopups={activePopups}
        toggleHandler={toggleHandler}
        isOpenChat={isOpenChat}
        startTime={startTime}
        setStartTime={setStartTime}
      />
      <MainBottom
        toggleHandler={toggleHandler}
        isOpenChat={isOpenChat}
        setIsOpenChat={setIsOpenChat}
      />
    </div>
  );
}

export default Main;
