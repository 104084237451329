import React from 'react';
import ClientSeedTop from './ClientSeedTop';
import CurrentSeed from './CurrentSeed';

const ClientSeed = ({ seeds }) => (
  <>
    <ClientSeedTop />
    {
      seeds.map((seed, index) => (
        <CurrentSeed key={index} gamer={seed} number={index + 1} />
      ))
    }
  </>
);
export default ClientSeed;
