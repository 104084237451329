import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { generateOddColor } from '../../../helpers/generateOddColor';
import { POPUPS } from '../../../constants/game';

const CoefficientList = ({ isOpen, toggleHandler, roundsHistory, setIsOpen }) => {
  const odds = useRef(null);
  useEffect(() => {
    if (isOpen) {
      odds.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isOpen]);
  return (
    <div className="coefficient__list-field">
      <div ref={odds} className={`coefficient__list ${isOpen ? 'open' : ''}`}>
        {roundsHistory.map((roundHistory, index) => {
          const { id, crash_value: crashValue } = roundHistory;
          const number = generateOddColor(crashValue);
          return (
            <div
              className={`coefficient__item wcolor-${number}`}
              key={index}
              onClick={() => toggleHandler(POPUPS.PROBABLY, id)}>
              {crashValue}x
            </div>
          );
        })}
      </div>
      <div className="coefficient-right">
        <button
          type="button"
          className={`coefficient__button ${isOpen ? 'open' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ roundsHistoryReducer }) => ({
  roundsHistory: roundsHistoryReducer,
});
export default connect(mapStateToProps)(CoefficientList);
