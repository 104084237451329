import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SVGIMG } from '../../../../constants/game';
import { useTranslation } from '../../../../context/LanguageProvider';
import { generateSeed } from '../../../../helpers/general';
import { pushToStorage } from '../../../../helpers/storageMenagement';
import { setManualSeed } from '../../../../redux/actions/gameActions';

function SeedPopup({ close, manualSeedReducer, setManualSeed }) {
  const [value, setValue] = useState(manualSeedReducer);
  const [isActive, setIsActive] = useState('');
  const isCanSave = value.length >= 4 && value.length <= 65;
  const outSideClick = (evt) => {
    const flyOutElement = document.querySelector('.popup-seed');
    let targetElement = evt.target;
    do {
      if (targetElement === flyOutElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    closePopup();
  };

  useEffect(() => {
    document.addEventListener('click', outSideClick);
    return () => {
      document.removeEventListener('click', outSideClick);
    };
  }, [outSideClick]);

  useEffect(() => {
    setTimeout(() => {
      setIsActive('active');
    }, 100);
  }, []);

  const closePopup = () => {
    setIsActive('');
    setTimeout(() => {
      close();
    }, 100);
  };

  const saveHandle = () => {
    if (isCanSave) {
      setManualSeed(value);
      pushToStorage('manualSeed', value);
      closePopup();
    }
  };

  const randomSeed = () => {
    const genValue = generateSeed(20);
    setValue(genValue);
  };

  const t = useTranslation();

  return (
    <div className={`popup-seed ${isActive}`}>
      <div className="popup-seed__header">
        <div className="popup-seen__title">{t('CHANGE SEED')}</div>
        <button type="button" className="popup-seed__close" onClick={closePopup}>
          {SVGIMG.changeButton}
        </button>
      </div>
      <div className="popup-seed__body">
        <div className="popup-seed__new">
          <div className="popup-seed__new-title">{t('Enter New Seed:')}</div>
          <input
            className="popup-seed__new-server"
            value={value}
            onKeyDown={(e) => /[^a-z0-9]+$/i.test(e.key) && e.preventDefault()}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="popup-seed__random">
          <button
            type="button"
            className="popup-seed__button popup-seed__random-button"
            onClick={randomSeed}>
            {t('Random')}
          </button>
        </div>
        <div className="popup-seed__bottom">
          <button
            type="button"
            className={`popup-seed__button popup-seed__${isCanSave ? 'save' : 'cancel'}-button`}
            onClick={saveHandle}
            disabled={!isCanSave}>
            {t('Save')}
          </button>
          <button
            type="button"
            className="popup-seed__button popup-seed__cancel-button"
            onClick={closePopup}>
            {t('Cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ manualSeedReducer }) => ({ manualSeedReducer });

const mapDispatchToProps = {
  setManualSeed,
};
export default connect(mapStateToProps, mapDispatchToProps)(SeedPopup);
