import React from 'react';
import { connect, useSelector } from 'react-redux';
import { SVGIMG } from '../../../../../constants/game';

const ConsoleTop = ({ isBetActive, cashOutResponse, type, gameState }) => {
  const language = useSelector((state) => state.activeLanguageReducer);
  const { is_won: isWon } = cashOutResponse[type];
  const { is_started: isStarted } = gameState;

  return (
    <div className="console-top">
      <div
        className="console-top__next-round"
        style={isBetActive && !isWon && isStarted ? { transform: 'scale(1)', opacity: 1 } : {}}>
        {language === 'sw' ? SVGIMG.nextRoundSw : SVGIMG.nextRound}
      </div>
    </div>
  );
};
const mapStateToProps = ({ cashOutDataReducer, gameStateReducer }) => ({
  cashOutResponse: cashOutDataReducer,
  gameState: gameStateReducer,
});
export default connect(mapStateToProps)(ConsoleTop);
