import React from 'react';
import { SVGIMG } from '../../../../constants/game';
import { useTranslation } from '../../../../context/LanguageProvider';

function ChatHeader({ chatHandler, usersOnlineCount }) {
  const t = useTranslation();

  return (
    <div className="chat__button" onClick={chatHandler}>
      <div className="chat__button-icon">
        <span className="chat__button-icon-left">{SVGIMG.chatButton}</span>
        <span className="chat__button-icon-right">{t('Chat')}</span>
      </div>
      <div className="chat__button-online">
        <span className="chat__button-online-left">{t('Online')}</span>
        <span className="chat__button-online-right">{usersOnlineCount.count}</span>
      </div>
      <div className="chat__button-arrow">{SVGIMG.chatArrowButton}</div>
    </div>
  );
}

export default ChatHeader;
