import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from '../../../../../context/LanguageProvider';
import OddButtons from './OddButtons';

const AMOUNT_TYPES = {
  IS_MORE: 'isMore',
  IS_LESS: 'isLess',
};

const { IS_LESS, IS_MORE } = AMOUNT_TYPES;

const defaultAmountState = { [IS_LESS]: false, [IS_MORE]: false };

const Amount = ({
  amount,
  setAmount,
  isFocused,
  setIsFocused,
  setStep,
  isBetActive,
  betResponse,
  type,
  userInfo,
  isActive,
}) => {
  const [isAmountEnough, setIsAmountEnough] = useState(defaultAmountState);
  const [isPushingButton, setIsPushingButton] = useState({ active: false, value: '' });
  const [faster, setFaster] = useState(400);
  const inputEl = useRef(null);
  const {
    is_accepted: isAccepted,
    is_canceled: isCanceled,
    is_won: isWonTicket,
    amount: betAmount,
  } = betResponse[type];

  const { minBet, maxBet, denomination } = userInfo;

  let pushingButton;

  const t = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line camelcase
    betResponse[type]?.amount && setAmount(betAmount);
  }, [betResponse[type]]);

  const repeatInc = () => {
    // eslint-disable-next-line no-plusplus
    if (Number(amount) + Number(denomination) <= maxBet) {
      setAmount(+amount + Number(denomination));
    } else {
      setAmount(maxBet);
      toolTipTimeOut(IS_MORE);
    }
  };
  const repeatDec = () => {
    // eslint-disable-next-line no-plusplus
    amount >= 2 * Number(denomination) && setAmount(+amount - Number(denomination));
    if (amount >= 2 * Number(denomination)) {
      setAmount(+amount - Number(denomination));
    } else {
      setAmount(minBet);
      toolTipTimeOut(IS_LESS);
    }
  };

  const onMouseDown = (e) => {
    setStep(0);
    setIsPushingButton(e);
    e.value === 'plus' && repeatInc();
    e.value === 'minus' && repeatDec();
  };

  const onMouseUp = (e) => {
    setFaster(400);
    clearInterval(pushingButton);
    setIsPushingButton(e);
  };

  const toolTipTimeOut = (type) => {
    setIsAmountEnough({ ...isAmountEnough, [type]: true });
    setTimeout(() => {
      setIsAmountEnough(defaultAmountState);
    }, 1000);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputEl.current.blur();
    }
  };

  const handleChangeAmount = ({ target: { value } }) => {
    const regex = /^[0-9]{0,9}?\d*\.?\d*$/;
    const valid = regex.test(value);
    if (
      !valid ||
      value[0] === '0' ||
      value[0] === '.' ||
      (value.split('.').length > 1 && value.split('.')[1].length > 2)
    )
      return;
    setAmount(value);
    setStep(0);
  };

  const handleAddAmount = (number) => {
    setStep((prev) => {
      if (prev === number) {
        const value = amount + number;
        setAmount(value);
      } else {
        setAmount(number);
      }
      return number;
    });
  };

  useEffect(() => {
    const splitArray = amount.toString().split('.');
    const { length } = splitArray;
    let inValid;
    if (length > 1) {
      const afterDot = splitArray[1];
      if (
        (afterDot[afterDot.length - 1] === '0' && afterDot[0] === '0') ||
        !afterDot[afterDot.length - 1]
      ) {
        inValid = true;
      }
    }
    if (!isFocused && amount < minBet) {
      setAmount(minBet);
      toolTipTimeOut(IS_LESS);
    } else if (amount > maxBet) {
      setAmount(maxBet);
      toolTipTimeOut(IS_MORE);
    } else if (!isFocused && inValid && splitArray[0] >= minBet) {
      setAmount(splitArray[0]);
    }
  }, [amount, isFocused]);

  useEffect(() => {
    setIsAmountEnough(defaultAmountState);
  }, []);

  const isClickedButton =
    isAccepted || isBetActive || !isActive || (isCanceled === false && !isWonTicket);
  useEffect(() => {
    const { active, value } = isPushingButton;
    if (active) {
      let x;
      // eslint-disable-next-line no-nested-ternary
      pushingButton = setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        // if (value === 'plus') {
        //   if (amount + minBet <= maxBet) {
        //     setAmount(amount + Number(minBet));
        //   } else {
        //     setAmount(maxBet);
        //     toolTipTimeOut(IS_MORE);
        //   }
        // } else if (value === 'minus') {
        //   if (amount >= 2 * minBet) {
        //     setAmount(amount - Number(minBet));
        //   } else {
        //     setAmount(minBet);
        //     toolTipTimeOut(IS_LESS);
        //   }
        // }
        // eslint-disable-next-line no-unused-expressions
        const speed = faster < 25 ? 25 : faster / 1.5;
        setFaster(speed);
      }, faster);
    } else {
      clearTimeout(pushingButton);
      setFaster(400);
    }
  }, [amount, isPushingButton]);
  return (
    <div
      className="console__buttons"
      style={isClickedButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}>
      <div className="console__buttons-top">
        <label className="circle">
          <input
            ref={inputEl}
            type="text"
            className="circle__input"
            onKeyPress={handleKeyPress}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={amount}
            onChange={handleChangeAmount}
            disabled={isClickedButton}
          />
          <span className="circle__letters-field">
            <span className="circle__letters" />
          </span>
        </label>
        <button
          type="button"
          className="console__buttons-minus"
          onMouseUp={() => onMouseUp({ active: false, value: '' })}
          onMouseLeave={() => onMouseUp({ active: false, value: '' })}
          onMouseDown={() => onMouseDown({ active: true, value: 'minus' })}>
          <span
            className={`console__buttons-tooltip ${
              isAmountEnough.isLess ? 'console__buttons-tooltip-active' : ''
            }`}>
            <span className="console__buttons-tooltip-inner">
              {t('Min')} {minBet}
            </span>
          </span>
          <span className="console__buttons-minus-line" />
        </button>
        <button
          type="button"
          className="console__buttons-plus"
          onMouseUp={() => onMouseUp({ active: false, value: '' })}
          onMouseLeave={() => onMouseUp({ active: false, value: '' })}
          onMouseDown={() => onMouseDown({ active: true, value: 'plus' })}>
          <span
            className={`console__buttons-tooltip ${
              isAmountEnough.isMore ? 'console__buttons-tooltip-active' : ''
            }`}>
            <span className="console__buttons-tooltip-inner">
              {t('Max')} {maxBet}
            </span>
          </span>
          <span className="console__buttons-minus-line" />
          <span className="console__buttons-minus-line console__button-plus-line" />
        </button>
        <div className="block-click" />
      </div>
      <OddButtons handleAddAmount={handleAddAmount} />
    </div>
  );
};

const mapStateToProps = ({ betResponseReducer, userInfoReducer }) => ({
  betResponse: betResponseReducer,
  userInfo: userInfoReducer,
});
export default connect(mapStateToProps)(Amount);
