import React, { useState } from 'react';
import LeftHeader from './LeftHeader/LeftHeader';
import LeftTabs from './LeftTabs/LeftTabs';

function LeftSide({ toggleHandler }) {
  const [ activeTab, setIsActiveTab ] = useState(1);
  const [ toolTip, setToolTip ] = useState(false);

  return (
    <aside className='left-menu'>
      <LeftHeader
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
      />
      <LeftTabs
        activeTab={activeTab}
        toggleHandler={toggleHandler}
        setToolTip={setToolTip}
        toolTip={toolTip}
      />
    </aside>
  );
  function handleActiveTab(id) {
    setIsActiveTab(id);
  }
}

export default LeftSide;
