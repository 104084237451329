import React from 'react';
import { useTranslation } from '../../../../context/LanguageProvider';

const GameHeader = ({ gamersCount }) => {
  const t = useTranslation();

  return (
    <div className="game-head">
      <div className="game-head__gamers game__gamers--width">
        <div className="game-head__gamers-text">{t('TOTAL')} </div>
        <div className="game-head__gamers-number">{gamersCount}</div>
      </div>
      <div className="game-head__bet game__bet--width">{t('BET')}</div>
      <div className="game-head__odd game__odd--width">X</div>
      <div className="game-head__win game__win--width">{t('WIN')}</div>
    </div>
  );
};
export default GameHeader;
