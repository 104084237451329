import React from 'react';
import moment from 'moment';
import { POPUPS, SVGIMG } from '../../../../constants/game';
import { useTranslation } from '../../../../context/LanguageProvider';

function BetHeader({ setToolTip, toggleHandler, createdAt, roundId, crashValue }) {
  const date = moment(createdAt);
  const dateByDay = date.format('DD.MM.YY');
  const dateByHours = date.format('HH:mm');
  let size;

  return (
    <div className="my-bets__head">
      <div className="my-bets__head-left">
        <div className="my-bets__head-id">
          {!crashValue && <span className="my-bets__head-id-icon">{SVGIMG.liveGame}</span>}
          <span className="my-bets__head-id-text">ID: {roundId}</span>
        </div>
        <div className="my-bets__head-time my-bets__head-time--line">{dateByDay}</div>
        <div className="my-bets__head-time">{dateByHours}</div>
      </div>
      <div className="my-bets__head-right">
        {crashValue && (
          <div
            onClick={() => toggleHandler(POPUPS.PROBABLY, roundId)}
            onMouseEnter={(e) => setToolTip({ coord: e.clientY - 153, text: 'Check fairness' })}
            onMouseLeave={() => setToolTip({ coord: false })}
            className="validity__icon"
          />
        )}
      </div>
    </div>
  );
}

export default BetHeader;
