import React, { useState } from 'react';
import { connect } from 'react-redux';
import ErrorPopup from './ErrorPopup';
import AvatarsPopup from './AvatarsPopup';
import ValidityPopup from './ValidityPopup';
import ProbablyPopup from './ProbablyPopup/ProbablyPopup';
import { POPUPS } from '../../../../constants/game';
import SeedPopup from './SeedPopup';
import { setSeedPopupChange } from '../../../../redux/actions/gameActions';

const { ERRORS, AVATARS, VALIDITY, PROBABLY } = POPUPS;

const Popups = ({
  activePopups,
  toggleHandler,
  userInfoReducer,
  seedPopupChangeReducer,
  // eslint-disable-next-line no-shadow
  setSeedPopupChange,
  notificationsReducer,
}) => {
  const [isActive, setIsActive] = useState(false);

  const closePopup = () => {
    setIsActive(false);
    setTimeout(() => {
      toggleHandler();
    }, 100);
  };

  const outSideClick = (evt, popupName) => {
    const flyOutElement = document.querySelector(`.popup-${popupName}`);
    let targetElement = evt.target;
    do {
      if (targetElement === flyOutElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    closePopup();
  };

  return (
    <div className="popup__field">
      <div
        className={`popup_background ${
          Object.values(activePopups).includes(true) ? 'popup_background_active' : ''
        }`}
      />
      {activePopups[ERRORS] && (
        <ErrorPopup
          popupName={ERRORS}
          toggleHandler={toggleHandler}
          isActive={activePopups[ERRORS]}
          setIsActive={setIsActive}
          outSideClick={outSideClick}
          closePopup={closePopup}
        />
      )}
      {activePopups[AVATARS] && (
        <AvatarsPopup
          popupName={AVATARS}
          toggleHandler={toggleHandler}
          isActive={activePopups[AVATARS]}
          outSideClick={outSideClick}
          closePopup={closePopup}
          setIsActive={setIsActive}
          selectedAvatar={userInfoReducer.avatar}
        />
      )}
      {activePopups[VALIDITY] && (
        <ValidityPopup
          isActive={isActive}
          setIsActive={setIsActive}
          outSideClick={outSideClick}
          closePopup={closePopup}
        />
      )}
      {activePopups[PROBABLY] && (
        <ProbablyPopup
          popups={POPUPS}
          toggleHandler={toggleHandler}
          isActive={isActive}
          setIsActive={setIsActive}
          outSideClick={outSideClick}
          closePopup={closePopup}
        />
      )}
      {seedPopupChangeReducer && (
        <SeedPopup seed={seedPopupChangeReducer} close={setSeedPopupChange} />
      )}
    </div>
  );
};

const mapStateToProps = ({ seedPopupChangeReducer, userInfoReducer, notificationsReducer }) => ({
  seedPopupChangeReducer,
  userInfoReducer,
  notificationsReducer,
});

const mapDispatchToProps = {
  setSeedPopupChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
