import React, { useEffect, useState } from 'react';
import ConsoleField from './ConsoleFields/ConsoleField';
import Amount from './ConsoleFields/Amount';
import AddTicket from './ConsoleFields/AddTicket';
import Settings from './ConsoleFields/Settings';
import BetButton from './ConsoleFields/BetButton';
import ConsoleTop from './ConsoleFields/ConsoleTop';
import { changeInput } from '../../../../helpers/general';
import { CONSOLE_TYPES, SVGIMG } from '../../../../constants/game';

const { FIRST } = CONSOLE_TYPES;

const FirstConsole = ({
  isSecondTicketActive,
  toggleHandler,
  amount,
  setAmount,
  autoCashOutOdd,
  setAutoCashOutOdd,
  isFocused,
  setIsFocused,
  setStep,
  isBetActive,
  setIsBetActive,
  isAutoGameActive,
  setIsAutoGameActive,
  canAddTicket,
  noteData,
  setNotifications,
}) => {
  const [isActive, setActive] = useState(true);
  // eslint-disable-next-line no-nested-ternary
  const styleName =
    noteData.content?.indexOf('You won') >= 0
      ? 'win'
      : noteData.content?.indexOf('Game Over!') >= 0
      ? 'loss'
      : '';

  useEffect(() => {
    changeInput(amount, FIRST, isFocused);
  }, [amount, isFocused]);

  useEffect(() => {
    noteData.active &&
      setTimeout(() => {
        setNotifications({
          [FIRST]: {
            active: false,
            content: null,
          },
        });
      }, 3000);
  }, [noteData]);

  return (
    <div
      className={`console console-first ${autoCashOutOdd.active ? 'console-qashout' : ''}  ${
        isSecondTicketActive ? 'active' : ''
      }`}>
      <div className="console__settings">
        <ConsoleTop isBetActive={isBetActive} type={FIRST} />
        <Amount
          amount={amount}
          setAmount={setAmount}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          setStep={setStep}
          isBetActive={isBetActive}
          type={FIRST}
          isActive={isActive}
        />
        <Settings
          setAutoCashOutOdd={setAutoCashOutOdd}
          autoCashOutOdd={autoCashOutOdd}
          setIsBetActive={setIsBetActive}
          isAutoGameActive={isAutoGameActive}
          setIsAutoGameActive={setIsAutoGameActive}
          isBetActive={isBetActive}
          type={FIRST}
          isActive={isActive}
          canAddTicket={canAddTicket}
        />
        <BetButton
          amount={amount}
          autoCashOutOdd={autoCashOutOdd}
          type={FIRST}
          isBetActive={isBetActive}
          setIsBetActive={setIsBetActive}
          isAutoGameActive={isAutoGameActive}
          setIsAutoGameActive={setIsAutoGameActive}
          isActive={isActive}
          setActive={setActive}
          canAddTicket={canAddTicket}
        />
        <AddTicket toggleHandler={toggleHandler} />
      </div>
      <div className={`console__notification ${styleName} ${noteData.active ? '' : 'active'}`}>
        {noteData.content}
      </div>
    </div>
  );
};
export default ConsoleField(FirstConsole);
