import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SVGIMG } from '../../../constants/game';
import { setIsActiveManualSeed, setSeedPopupChange } from '../../../redux/actions/gameActions';
import { pushToStorage } from '../../../helpers/storageMenagement';
import { useTranslation } from '../../../context/LanguageProvider';

function ManualInput({
  // eslint-disable-next-line no-shadow
  setSeedPopupChange,
  isActive,
  setIsActiveManualSeed,
  manualSeedReducer,
}) {
  const [hoverToolTip, setHoverToolTip] = useState(false);
  const seed = manualSeedReducer;

  let size;

  const t = useTranslation();

  const clickTypeHandle = () => {
    pushToStorage('seedType', 'manual');
    pushToStorage('manualSeed', seed);
    setIsActiveManualSeed(true);
  };

  const clickChangePopup = () => {
    // eslint-disable-next-line no-unused-expressions
    isActive && setSeedPopupChange();
  };

  return (
    <div className="settings__hash-item">
      <label className="settings__hash-label" onClick={clickTypeHandle}>
        <input
          type="radio"
          name="radio"
          className="settings__hash-input"
          defaultChecked={isActive}
        />
        <span className="settings__hash-radio" />
        <span className="settings__hash-radio-text">{t('Enter Manually')}</span>
      </label>
      <div className="settings__hash-bottom">
        <label className="settings__hash-bottom-text settings__hash-bottom-text--change">
          <span className="settings__hash-current">{t('Current')}</span>
          <input id="manualSeedInput" className="settings__hash-code" value={seed} readOnly />
          <button
            type="button"
            className="settings__hash-copy"
            ref={(el) => {
              if (!el) return;
              size = el.getBoundingClientRect().top - 35;
            }}
            onClick={seedCopy}
            onMouseEnter={() => setHoverToolTip({ coord: size, text: 'Copy' })}
            onMouseLeave={() => setHoverToolTip({ coord: false })}>
            {SVGIMG[2]}
          </button>
          {hoverToolTip.coord && (
            <div
              className="hoverTooltip hoverTooltip--active"
              style={{ top: `${hoverToolTip.coord}px`, right: '70px' }}>
              <div className="hoverTooltip__field">{t(hoverToolTip.text)}</div>
            </div>
          )}
        </label>
        <button
          type="button"
          className="settings__hash-bottom-change"
          style={{ cursor: `${isActive ? 'pointer' : 'default'}` }}
          onClick={clickChangePopup}>
          {t('Change')}
        </button>
      </div>
    </div>
  );

  function seedCopy() {
    setHoverToolTip({ coord: size, text: 'The seed is copied' });
    const copyText = document.getElementById('manualSeedInput');
    copyText.select();
    document.execCommand('copy');
  }
}

const mapStateToProps = ({
  seedPopupChangeReducer,
  isActiveManualSeedReducer,
  manualSeedReducer,
}) => ({
  seedPopupChangeReducer,
  isActive: isActiveManualSeedReducer,
  manualSeedReducer,
});

const mapDispatchToProps = {
  setSeedPopupChange,
  setIsActiveManualSeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualInput);
