import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

BurgerMenuItems.propTypes = {
  activeBurgerTab: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  handleActiveBurgerTab: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

function BurgerMenuItems({ activeBurgerTab, id, handleActiveBurgerTab, name }) {
  const language = useSelector((state) => state.activeLanguageReducer);
  return (
    <li className="settings__item">
      <a
        className={`settings__link ${activeBurgerTab === id ? 'active' : ''}`}
        style={{ fontSize: language === 'sw' ? 13 : '' }}
        onClick={() => handleActiveBurgerTab(id)}>
        {name}
      </a>
    </li>
  );
}

export default BurgerMenuItems;
