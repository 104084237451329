import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import {
  fillBetResponse,
  resetBetResponse,
  resetCashOutData,
  updateCashOutData,
  setCurrentMyBets,
} from '../../../../../redux/actions/roundActions';
// eslint-disable-next-line import/no-cycle,import/named
import {
  betCancel,
  betPlace,
  betCashOut,
  getMyBets,
  getCurrentMyBets,
  getBalance,
} from '../../../../../Home';
import {
  setMyBetsList,
  setNotifications,
  setSoundData,
} from '../../../../../redux/actions/gameActions';
import { CONSOLE_TYPES } from '../../../../../constants/game';
import { useTranslation } from '../../../../../context/LanguageProvider';

const { FIRST, SECOND } = CONSOLE_TYPES;

const BUTTON_TEXT = {
  CANCEL: 'CANCEL',
  CASHOUT: 'CASHOUT',
};

const { CANCEL, CASHOUT } = BUTTON_TEXT;

const BetButton = ({
  // eslint-disable-next-line no-shadow,max-len
  amount,
  autoCashOutOdd,
  randomSeedReducer,
  betResponse,
  gameState,
  odd,
  type,
  isBetActive,
  setIsBetActive,
  // eslint-disable-next-line no-shadow
  currentMyBetsReducer,
  fillBetResponse,
  cashOutResponse,
  resetBetResponse,
  resetCashOutData,
  // eslint-disable-next-line no-shadow
  updateCashOutData,
  myBetsListReducer,
  setIsAutoGameActive,
  isAutoGameActive,
  setCurrentMyBets,
  // eslint-disable-next-line no-shadow
  setSoundData,
  soundDataReducer,
  setNotifications,
  popupToggleHandler,
  userInfo,
  isActive,
  setActive,
  errorsReducer,
  setMyBetsList,
  canAddTicket,
  isActiveManualSeedReducer,
  manualSeedReducer,
}) => {
  const [buttonText, setButtonText] = useState('');
  const [betData, setBetData] = useState({});
  const [cashOutAmount, setCashOutAmount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [gameOverNote, setGameOverNote] = useState({ first: false, second: false });
  const language = useSelector((state) => state.activeLanguageReducer);

  const { currency } = userInfo;
  const {
    bet_id: betId,
    amount: betAmount,
    is_accepted: isAccepted,
    is_canceled: isCanceled,
    is_won: isWonTicket,
    transaction_id: transactionID,
  } = betResponse[type];
  const { is_started: isStarted, is_crashed: isCrashed } = gameState;
  const {
    is_accepted: isCashOutAccepted,
    is_won: isWon,
    cashout_amount: winAmount,
  } = cashOutResponse[type];
  const betHandler = () => {
    // eslint-disable-next-line no-unused-vars
    const cashOutOdd = autoCashOutOdd.active ? autoCashOutOdd.odd : null;
    let seed;
    if (isActiveManualSeedReducer) {
      seed = manualSeedReducer;
    } else {
      seed = randomSeedReducer;
    }
    const data = {
      c: type,
      seed,
      amount,
      auto_cash_out_odd: cashOutOdd,
    };
    setBetData(data);
  };

  const betCancelHandler = () => {
    if ((transactionID || betId) && !isStarted) {
      setNotifications({ [type]: { active: true, content: `${t('Your Bet is cancelled!')}` } });
      setGameOverNote({ ...gameOverNote, [type]: false });
      betCancel(betId);
      setMyBetsList(myBetsListReducer.slice(10, myBetsListReducer.length));
    } else {
      setIsBetActive(false);
    }
  };

  const betCashOutHandler = () => {
    betCashOut({ bet_id: betId });
  };
  const handleClick = () => {
    setDisabled(true);
    if (isBetActive || (!isStarted && !isCrashed && isWonTicket === false)) {
      betCancelHandler();
    } else if (isStarted && !isCrashed && isWonTicket === false) {
      betCashOutHandler();
    } else {
      setIsBetActive(true);
    }
    setTimeout(() => {
      setDisabled(false);
    }, 700);
    setSoundData({ betButton: !soundDataReducer.betButton });
  };

  useEffect(() => {
    if (currentMyBetsReducer.length) {
      const currentBets = currentMyBetsReducer.reduce((acc, el) => {
        acc[el.c] = el;
        return acc;
      }, {});
      fillBetResponse(currentBets);
    } else {
      resetBetResponse();
    }
  }, [currentMyBetsReducer]);

  useEffect(() => {
    let timeOutId;
    if (isCashOutAccepted && isWon) {
      const currentWinAmount = winAmount.toFixed(2);
      setMyBetsList(myBetsListReducer.slice(10, myBetsListReducer.length));
      setCashOutAmount(currentWinAmount);
      setNotifications({
        [type]: { active: true, content: `${t('You won')} ${currentWinAmount} ${currency}!` },
      });
      setGameOverNote({ ...gameOverNote, [type]: false });
      getMyBets({ limit: 10, includeCurrentRound: true });
      setActive(false);
      timeOutId = setTimeout(() => {
        updateCashOutData(cashOutResponse[type]);
        if (isAutoGameActive) {
          setIsBetActive(true);
          setButtonText(CANCEL);
        } else {
          setIsBetActive(false);
        }
        setCashOutAmount(0);
        setActive(true);
      }, 2000);
    }
    return () => clearTimeout(timeOutId);
  }, [isCashOutAccepted, isWon, winAmount, isAutoGameActive]);

  useEffect(() => {
    let timeOutId;
    if (!isStarted && !isCrashed && betData.seed) {
      const types = { [FIRST]: 0, [SECOND]: 500 };
      timeOutId = setTimeout(() => {
        betPlace(betData);
      }, types[type]);
    }
    return () => clearTimeout(timeOutId);
  }, [betData, isStarted]);

  useEffect(() => {
    if (!isStarted && !isCrashed && !!transactionID && isAccepted) {
      setNotifications({ [type]: { active: true, content: `${t('Your Bet is accepted!')}` } });
      setGameOverNote({ ...gameOverNote, [type]: true });
      getMyBets({ limit: 10, includeCurrentRound: true });
    }
  }, [transactionID, isStarted, isCrashed]);

  useEffect(() => {
    if (!isStarted && !isCrashed && (isAccepted || isWonTicket === false)) {
      setButtonText(CANCEL);
      setIsBetActive(false);
      setBetData({});
    } else if (isStarted && !isCrashed && (isAccepted || isWonTicket === false)) {
      setButtonText(CASHOUT);
    } else if (!isStarted && !isCrashed && isAccepted === false) {
      setIsBetActive(false);
      setBetData({});
    } else if (isStarted === true && isCrashed === true) {
      resetBetResponse();
      resetCashOutData();
      setCashOutAmount(0);
      setActive(true);
      setCurrentMyBets([]);
      if (isAutoGameActive) {
        setButtonText(CANCEL);
        setIsBetActive(true);
      }
    }
    isStarted && !isCrashed && setSoundData({ rocketStart: true });
    !isStarted && setSoundData({ rocketStart: false });
    isCrashed && setSoundData({ rocketCrashed: true });
    !isCrashed && setSoundData({ rocketCrashed: false });
  }, [isAccepted, isStarted, isCrashed, isCanceled]);

  useEffect(() => {
    if (isBetActive && !isWon) {
      setButtonText(CANCEL);
      betHandler();
    } else {
      setBetData({});
    }
  }, [isBetActive, transactionID]);

  useEffect(() => {
    if (isStarted === true && isCrashed === true) {
      if (!betResponse[type].is_won && betResponse[type].bet_id && gameOverNote[type]) {
        setNotifications({ [type]: { active: true, content: `${t('Game Over!')}` } });
        setGameOverNote({ ...gameOverNote, [type]: false });
        setMyBetsList(myBetsListReducer.slice(10, myBetsListReducer.length));
      }
      getMyBets({ limit: 10, includeCurrentRound: true });
      getBalance();
    }
  }, [isStarted, isCrashed]);

  const isClickedButton =
    isAccepted || isBetActive || !isActive || (isCanceled === false && !isWonTicket);

  useEffect(() => {
    const isClicked =
      isAccepted || isBetActive || !isActive || (isCanceled === false && !isWonTicket);
    !isClicked && setIsAutoGameActive(false);
  }, [isAccepted, isBetActive, isActive, isCanceled, isWonTicket]);

  useEffect(() => {
    !isAutoGameActive && betCancelHandler();
  }, [isAutoGameActive]);

  const t = useTranslation();

  return (
    <div className="console__bet" style={!isActive ? { pointerEvents: 'none' } : {}}>
      <button
        onClick={handleClick}
        type="button"
        disabled={disabled || !canAddTicket}
        className={`console__bet-button ${canAddTicket ? 'disable' : ''} ${
          isClickedButton ? 'push' : ''
        }`}
        style={
          isClickedButton
            ? {
                transform: 'translateY(1px)',
                boxShadow: '0 1px rgba(var(--rocket-gray10), 1)',
                backgroundColor: 'rgba(var(--rocket-gray-shadow), 1)',
              }
            : {}
        }>
        <span
          className="console__bet-button-qashout"
          style={isClickedButton ? { display: 'block' } : {}}>
          <span
            className="console__bet-button-qashout-top"
            style={!canAddTicket ? { display: 'none' } : { display: 'block' }}>
            {buttonText === CANCEL ? '' : cashOutAmount || (odd * betAmount).toFixed(2)}
          </span>
          <span
            className="console__bet-button-qashout-bottom"
            style={!canAddTicket ? { display: 'none' } : {}}>
            {!cashOutAmount && t(buttonText)}
          </span>
        </span>
        <span
          className="console__bet-button-text"
          style={
            isClickedButton
              ? { display: 'none', fontSize: language === 'sw' ? 18 : '' }
              : { fontSize: language === 'sw' ? 18 : '' }
          }>
          {t('BET')}
        </span>
        <span className="lds-spinner">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </span>
      </button>
      <span className={`console__bet-button-layer ${isClickedButton ? 'push' : ''}`} />
    </div>
  );
};

const mapStateToProps = ({
  randomSeedReducer,
  betResponseReducer,
  gameStateReducer,
  oddReducer,
  userInfoReducer,
  currentMyBetsReducer,
  cashOutDataReducer,
  soundDataReducer,
  myBetsListReducer,
  errorsReducer,
  manualSeedReducer,
  isActiveManualSeedReducer,
}) => ({
  randomSeedReducer,
  betResponse: betResponseReducer,
  gameState: gameStateReducer,
  odd: oddReducer,
  currentMyBetsReducer,
  cashOutResponse: cashOutDataReducer,
  soundDataReducer,
  myBetsListReducer,
  userInfo: userInfoReducer,
  errorsReducer,
  isActiveManualSeedReducer,
  manualSeedReducer,
});

const mapDispatchToProps = {
  fillBetResponse,
  resetBetResponse,
  resetCashOutData,
  updateCashOutData,
  setCurrentMyBets,
  setSoundData,
  setNotifications,
  setMyBetsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetButton);
