import React from 'react';
import avatars from '../../../../assets/img/avatars/avatars';

const GameItem = ({ item, number }) => {
  const {
    // eslint-disable-next-line camelcase
    amount, cashout_amount, cashout_odd, nickname, avatar,
  } = item;
  return (
    // eslint-disable-next-line camelcase
    <div className={`game__item ${cashout_amount && 'game__item--light'} `}>
      <div className='game__gamers game__gamers--width'>
        <div className='game__gamers-photo'>
          <img src={avatars[avatar]} alt='avatar' className='avatars-img' />
        </div>
        <div className='game__gamers-number '>{nickname}</div>
      </div>
      <div className='game__bet game__bet--width'>
        {amount}
      </div>
      <div className={`game__odd game__odd--width wcolor-${number}`}>
        {/* eslint-disable-next-line camelcase */}
        {cashout_odd}x
      </div>
      {/* eslint-disable-next-line camelcase */}
      <div className={`game__win game__win--width ${!cashout_amount && 'game__win--empty'}`}>
        {/* eslint-disable-next-line camelcase */}
        {cashout_amount ? cashout_amount.toFixed(2) : '- - -'}
      </div>
    </div>
  );
};

export default GameItem;
