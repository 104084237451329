import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProbablyHeader from './ProbablyHeader';
import ProbablyBody from './ProbablyBody';
import { setRoundInfo } from '../../../../../redux/actions/roundActions';
import { useTranslation } from '../../../../../context/LanguageProvider';

const ProbablyPopup = ({
  // eslint-disable-next-line no-shadow
  toggleHandler,
  popups,
  isActive,
  setIsActive,
  closePopup,
  setRoundInfo,
  outSideClick,
  roundData,
}) => {
  const handleClick = (event) => {
    outSideClick(event, 'probably');
  };

  useEffect(() => {
    setIsActive(true);
    document.addEventListener('click', handleClick);
    return () => {
      setRoundInfo('');
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const t = useTranslation();

  return (
    <div className={`popup-probably ${isActive ? 'active' : ''}`}>
      <ProbablyHeader closePopup={closePopup} roundData={roundData} />
      <div className="popup-scroll-body">
        <ProbablyBody roundData={roundData} />
        <div className="popup-probably__footer">
          <div className="popup-probably__footer-left">{t('For Instructions check')}</div>
          <div
            className="popup-probably__footer-right"
            onClick={() => toggleHandler(popups.VALIDITY)}>
            {t('What is Provable Fairness?')}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ roundInfoReducer }) => ({
  roundData: roundInfoReducer,
});

const mapDispatchToProps = {
  setRoundInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProbablyPopup);
