import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import images from '../../../../assets/img';
import { SVGIMG } from '../../../../constants/game';

const { error } = images;

const ErrorPopup = ({
  isActive, errorsReducer, gameState, currentMyBets, closePopup,
}) => {
  const { is_started: isStarted, is_crashed: isCrashed } = gameState;

  useEffect(() => {
    errorsReducer.errorId && isStarted && !isCrashed && currentMyBets.length && closePopup();
  }, [ isStarted, isCrashed, currentMyBets ]);

  return (
    <div className={`popup-error ${isActive ? 'active' : ''}`}>
      <button
        type='button'
        className='popup-seed__close'
        onClick={closePopup}
      >
        {SVGIMG.changeButton}
      </button>
      <div className='popup-error__center'>
        <div className='popup-error__icon'>
          <img className='popup-error__icon-svg' src={error} alt='Error Icon' />
        </div>
        <div className='popup-error__text'>
          {errorsReducer.messages}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ errorsReducer, gameStateReducer, currentMyBetsReducer }) => ({
  errorsReducer,
  gameState: gameStateReducer,
  currentMyBets: currentMyBetsReducer,
});

export default connect(mapStateToProps)(ErrorPopup);
