import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateOddColor } from '../../../helpers/generateOddColor';

const Rocket = ({ odd, activeOptions, gameState }) => {
  const [isVisible, setIsVisible] = useState(false);
  const number = generateOddColor(odd);
  const { is_started: isStarted } = gameState;

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 0);
    setIsVisible(false);
  }, [gameState]);

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'visible') {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    document.addEventListener('visibilitychange', listener);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener('visibilitychange', listener);
  }, []);
  return (
    <>
      <div className="rocket__coefficient-field">
        <div className="rocket__coefficient">
          <span className={`rocket__coefficient-background wcolor-${number}`} />
          <span className="rocket__coefficient-count">{odd}</span>
          <span className="rocket__coefficient-x">{odd && 'x'}</span>
        </div>
      </div>
      {/* {activeOptions.ball ? (
        <>
          <div className="console-background--z1" />
          <div className="console-background--z2" />
        </>
      ) : (
        ''
      )} */}
      <div className="rocket">
        {isVisible && (
          <div className="rocket__time-field" style={isStarted ? { opacity: 0 } : {}}>
            <div className="rocket__time" />
          </div>
        )}
        {activeOptions.ball ? (
          <>
            <div className="ball__field" />
            <div className={` ${isStarted ? 'ball__inner' : 'ball_static'}`} />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ oddReducer, activeOptionsReducer, gameStateReducer }) => ({
  odd: oddReducer,
  activeOptions: activeOptionsReducer,
  gameState: gameStateReducer,
});
export default connect(mapStateToProps)(Rocket);
