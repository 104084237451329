import { useEffect } from 'react';

export function forMap(iterationCount, cb) {
  forMap.break = '#34#null#break#hdk89';

  const arr = [];
  if (typeof iterationCount !== 'number' || isNaN(iterationCount)) {
    throw new TypeError('first arg should be a number');
  }
  if (typeof cb !== 'function') {
    throw new TypeError(`${cb} is not a function`);
  }

  for (let index = 0; index < iterationCount; index += 1) {
    const val = cb(index, iterationCount);
    if (val === forMap.break) {
      break;
    }
    arr.push(val);
  }
  return arr;
}

export function sendResponse(socket, ...data) {
  const str = JSON.stringify(data);
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  socket.send(buf);
}

export const changeInput = (amount, current, isFocused) => {
  const currentConsole = document.querySelector(`.console-${current}`);
  const letters = currentConsole.querySelector('.circle__letters');
  let firstLetter = letters.firstElementChild;
  while (firstLetter) {
    firstLetter.remove();
    firstLetter = letters.firstElementChild;
  }
  const value = amount.toString();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= value.length; i++) {
    const angle = -value.length * 3.5 + i * 7 + 5;
    const letter = document.createElement('span');
    const letterBlock = document.createElement('span');
    letterBlock.textContent = isFocused && (!value.length || i === value.length) ? '|' : value[i];
    letter.appendChild(letterBlock);
    letters.appendChild(letter);
    letterBlock.style.transform = `rotate(${angle}deg) translateY(-135px)`;
    letter.classList.add('circle__letter');
    if ((i === value.length || !value.length) && isFocused) {
      letter.classList.add('blinker');
      !value.length && (letterBlock.style.transform = 'rotate(0) translateY(-135px)');
    }
  }
};

export function generateSeed(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function detectRocketBrowser(rocketBrowser) {
  if (!!window.opr || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
    // Opera 8.0+
    rocketBrowser = 'isOpera';
  } else if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
    // Chrome 1 - 79
    rocketBrowser = 'isChrome';
  } else if (typeof InstallTrigger !== 'undefined') {
    // // Firefox 1.0+
    rocketBrowser = 'isFirefox';
  } else if (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNOTIFICATIONS]';
    })(!window.safari)
  ) {
    // Safari 3.0+ "[object HTMLElementConstructor]"
    rocketBrowser = 'isSafari';
  } else if (/* @cc_on!@ */ false || !!document.documentMode) {
    // Internet Explorer 6-11
    rocketBrowser = 'isIE';
  } else if (!(/* @cc_on!@ */ (false || !!document.documentMode)) && !!window.StyleMedia) {
    // Edge 20+
    rocketBrowser = 'isEdge';
  } else {
    rocketBrowser = 'other';
  }
  return rocketBrowser;
}

export function getTokenFromUrl() {
  const splitUrl = window.location.href.split('/');
  const tokenMatch = splitUrl.find((el) => el.includes('token='));
  return tokenMatch ? tokenMatch.split('token=')[1] : '';
}

export function getLanguageFromUrl() {
  const splitUrl = window.location.href.split('/');
  const tokenMatch = splitUrl.find((el) => el.includes('lang='));
  return tokenMatch ? tokenMatch.split('lang=')[1] : 'en';
}
