export const dataTypes = {
  SET_ID: 'SET_ID',
  SET_ROUND_INFO: 'SET_ROUND_INFO',
  SET_ROUNDS_HISTORY: 'SET_ROUNDS_HISTORY',
  SET_ODD: 'SET_ODD',
  SET_GAME_STATE: 'SET_GAME_STATE',
  SET_GAME_BETS: 'SET_GAME_BETS',
  RESET_GAME_BETS: 'RESET_GAME_BETS',
  SET_LEADERS_LIST: 'SET_LEADERS_LIST',
  SET_MY_BETS_LIST: 'SET_MY_BETS_LIST',
  UPDATE_MY_BETS_LIST: 'UPDATE_MY_BETS_LIST',
  SET_PREVIOUS_GAME: 'SET_PREVIOUS_GAME',
  SET_BALANCE: 'SET_BALANCE',
  SET_CHAT_HISTORY: 'SET_CHAT_HISTORY',
  SET_USER_INFO: 'SET_USER_INFO',
  UPDATE_CHAT_HISTORY: 'UPDATE_CHAT_HISTORY',
  UPDATE_LIKES: 'UPDATE_LIKES',
  SET_ERRORS_DATA: 'SET_ERRORS_DATA',
  SET_NOTIFICATIONS_DATA: 'SET_NOTIFICATIONS_DATA',
  SET_ONLINE_USERS_COUNT: 'SET_ONLINE_USERS_COUNT',
  SET_ACTIVE_LANGUAGE: 'SET_ACTIVE_LANGUAGE',
};

export const seedPopup = {
  SET_SEED_POPUP_CHANGE: 'SET_SEED_POPUP_CHANGE',
};

export const seedTypes = {
  SET_RANDOM_SEED: 'SET_RANDOM_SEED',
  SET_MANUAL_SEED: 'SET_MANUAL_SEED',
  SET_IS_ACTIVE_MANUAL_SEED: 'SET_IS_ACTIVE_MANUAL_SEED',
};

export const betDataTypes = {
  SET_BET_DATA: 'SET_BET_DATA',
  SET_BET_RESPONSE: 'SET_BET_RESPONSE',
  UPDATE_BET_RESPONSE: 'UPDATE_BET_RESPONSE',
  FILL_BET_RESPONSE: 'FILL_BET_RESPONSE',
  RESET_BET_RESPONSE: 'RESET_BET_RESPONSE',
  SET_CURRENT_MY_BETS: 'SET_CURRENT_MY_BETS',
  SET_CASH_OUT_DATA: 'SET_CASH_OUT_DATA',
  RESET_CASH_OUT_DATA: 'RESET_CASH_OUT_DATA',
  UPDATE_CASH_OUT_DATA: 'UPDATE_CASH_OUT_DATA',
};

export const soundDataTypes = {
  SET_SOUND_DATA: 'SET_SOUND_DATA',
};

export const optionsType = {
  SET_OPTIONS: 'SET_OPTIONS',
};

export const serverSeedType = {
  SET_SERVER_SEED: 'SET_SERVER_SEED',
};
