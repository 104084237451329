import React from 'react';
import { useTranslation } from '../../../../../context/LanguageProvider';

const ResultRow = ({ name, result }) => {
  const t = useTranslation();

  return (
    <div className="popup-probably__row-item">
      <div className="popup-probably__row-item-top">{t(name)}</div>
      <div className="popup-probably__row-item-bottom">{result}</div>
    </div>
  );
};
export default ResultRow;
