import React from 'react';
import PropTypes from 'prop-types';
import { generateOddColor } from '../../../../helpers/generateOddColor';
import { POPUPS } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
// eslint-disable-next-line import/no-cycle
import { setChatNewMessage } from '../../../../Home';
import { useTranslation } from '../../../../context/LanguageProvider';

LeaderBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

function LeaderBody({ data, index, setToolTip, toggleHandler, userInfoReducer }) {
  const {
    avatar,
    cashout_odd: cashOutOdd,
    nickname,
    currency,
    amount,
    cashout_amount: cashOutAmount,
    round_id: roundId,
  } = data;
  let size;
  const oddCols = {
    0: 'leaders__item--first',
    1: 'leaders__item--second',
    2: 'leaders__item--third',
  };
  const number = generateOddColor(cashOutOdd);

  return (
    <div className={`leaders__item ${oddCols[index] || ''}`}>
      <div className="leaders__gamers leaders__gamers--width">
        <div className="game__gamers-photo">
          <img src={avatars[avatar]} alt="avatar" className="avatars-img" />
        </div>
        <div className="game__gamers-number">{nickname}</div>
      </div>
      <div className="leaders__bet leaders__bet--width">{amount}</div>
      <div className={`leaders__odd leaders__odd--width wcolor-${number}`}>{cashOutOdd}x</div>
      <div className="leaders__win leaders__win--width">{cashOutAmount.toFixed(2)}</div>
      <div className="leaders__icons leaders__icons--width">
        <div
          onClick={() => toggleHandler(POPUPS.PROBABLY, roundId)}
          className="validity__icon"
          onMouseEnter={(e) => setToolTip({ coord: e.clientY - 153, text: 'Check fairness' })}
          onMouseLeave={() => setToolTip({ coord: false })}
        />
        <button
          onClick={() =>
            setChatNewMessage({
              type: 1,
              amount,
              avatar,
              nickname,
              currency,
              cashOutAmount,
              cashOutOdd,
              userNickname: userInfoReducer.nickname,
              userAvatar: userInfoReducer.avatar,
              roundId,
            })
          }
          type="button"
          className="chat-share"
          onMouseEnter={(e) => setToolTip({ coord: e.clientY - 153, text: 'Share to chat' })}
          onMouseLeave={() => setToolTip({ coord: false })}
        />
      </div>
    </div>
  );
}

export default React.memo(LeaderBody);
