import React from 'react';
import ServerSeed from './ServerSeed';
import ClientSeed from './ClientSeed/ClientSeed';
import Result from './Result';

const ProbablyBody = ({ roundData }) => {
  const {
    crash_value: crashValue, decimal, hex,
    sha256_server_seed: sha256ServerSeed, sha512, seeds,
  } = roundData;
  const usersSeed = roundData.seeds ? [ ...seeds, {}, {}, {}].slice(0, 3) : [];
  return (
    <div className='popup-probably__center'>
      <ServerSeed serverSeed={sha256ServerSeed} />
      <ClientSeed seeds={usersSeed} />
      <Result hex={hex} decimal={decimal} crashValue={crashValue} sha512={sha512} />
    </div>
  );
};
export default ProbablyBody;
