import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './assets/sass/style.scss';
// eslint-disable-next-line import/no-cycle
import LeftSide from './components/LeftSide/LeftSide';
// eslint-disable-next-line import/no-cycle
import Main from './components/Main/Main';
// eslint-disable-next-line import/named,import/no-cycle
import { getRoundData } from './Home';
import { setBetData, setOdd, setResetGameBets } from './redux/actions/roundActions';
import {
  setRandomSeed,
  setIsActiveManualSeed,
  setRoundsHistory,
} from './redux/actions/gameActions';
import { POPUPS } from './constants/game';
import { detectRocketBrowser, generateSeed } from './helpers/general';
import sounds from './assets/sounds';

const { ERRORS, AVATARS, VALIDITY, PROBABLY } = POPUPS;

const popupDefaultOptions = {
  [ERRORS]: false,
  [AVATARS]: false,
  [VALIDITY]: false,
  [PROBABLY]: false,
};

function App({
  // eslint-disable-next-line no-shadow
  gameState,
  setCurrentOdd,
  roundInfo,
  setRandomSeed,
  errorsReducer,
  notificationsReducer,
  // eslint-disable-next-line no-shadow
  setIsActiveManualSeed,
  soundDataReducer,
  setResetGameBets,
  roundsHistory,
  // eslint-disable-next-line no-shadow
  activeOptions,
  setRoundsHistory,
}) {
  const [scale, setScale] = useState(1);
  const [rocketBrowser, setRocketBrowser] = useState('');
  const [iFrameMusicIsActive, setIFrameMusicIsActive] = useState(false);
  const [isSoundsOn, setIsSoundsOn] = useState(false);
  const [styles, setStyles] = useState('');
  const [startTime, setStartTime] = useState(0);
  const [hoverMusic, setHoverMusic] = useState(false);
  const [activePopups, setActivePopups] = useState({ ...popupDefaultOptions });
  const {
    round_id: roundId,
    is_started: isStarted,
    is_crashed: isCrashed,
    launch_time: launchTime,
    server_time: serverTime,
  } = gameState;
  const toggleHandler = (popupName, id = '') => {
    if (!popupName) {
      setActivePopups(popupDefaultOptions);
    } else {
      setActivePopups({ ...popupDefaultOptions, [popupName]: true });
    }
    !!id && !roundInfo && getRoundData(id);
  };
  useEffect(() => {
    !!errorsReducer.messages && toggleHandler(POPUPS.ERRORS);
  }, [errorsReducer]);

  useEffect(() => {
    // sounds & music

    const optionsParsed = activeOptions;
    setIFrameMusicIsActive(optionsParsed.music);
    setIsSoundsOn(optionsParsed.sound);
  }, [soundDataReducer]);

  useEffect(() => {
    const rBrowser = detectRocketBrowser();
    setRocketBrowser(rBrowser);
  }, []);

  const resize = () => {
    const minWidth = 1024;
    const minHeight = 560;
    let value = 1;
    if (window.innerWidth <= minWidth || window.innerHeight <= minHeight) {
      // eslint-disable-next-line max-len
      const minValue =
        window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
      value = minValue / minHeight;
    }
    setScale(value);
  };
  const updateRoundsHistory = (gameData) => {
    const dataCopy = [gameData, ...roundsHistory];
    dataCopy.pop();
    setRoundsHistory(dataCopy);
  };

  useEffect(() => {
    if (isSoundsOn) {
      const rocketCrashAudio = document.getElementsByClassName('rocket-crash-audio-element')[0];
      const rocketStartAudio = document.getElementsByClassName('rocket-start-audio-element')[0];
      soundDataReducer.rocketCrashed && rocketCrashAudio.play();
      soundDataReducer.rocketStart && !soundDataReducer.rocketCrashed && rocketStartAudio.play();
    }
  }, [soundDataReducer.rocketStart, soundDataReducer.rocketCrashed]);

  useEffect(() => {
    if (isSoundsOn) {
      const rocketBetAudio = document.getElementsByClassName('rocket-bet-audio-element')[0];
      rocketBetAudio.play();
    }
  }, [soundDataReducer.betButton]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    window.addEventListener('load', resize);

    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('load', resize);
    };
  }, []);

  useEffect(() => {
    const difference = (launchTime - serverTime) / 1000;
    setStartTime(difference);
    setRandomSeed(generateSeed(20));
  }, [roundId, activeOptions.ball]);

  useEffect(() => {
    if (isStarted === false && isCrashed === false) {
      setResetGameBets({});
      setCurrentOdd({ current_odd: '' });
      // eslint-disable-next-line consistent-return
      setStyles((prev) => {
        if (prev === 'start') {
          return 'start';
        }
        setStyles('');
        setTimeout(() => {
          setStyles('start');
        }, 0);
      });
    } else if (isStarted === true && isCrashed === false) {
      if (activeOptions.ball) {
        setTimeout(() => {
          setStyles('start background-fast');
        }, 0);
      } else {
        setTimeout(() => {
          setStyles('start');
        }, 0);
      }
    } else if (isStarted === true && isCrashed === true) {
      if (activeOptions.ball) {
        setTimeout(() => {
          setStyles('start end animation');
        }, 0);
      } else {
        setTimeout(() => {
          setStyles('start end');
        }, 0);
      }
      const { crash_value: crashValue } = gameState;
      const gameData = { id: roundId, crash_value: crashValue };
      updateRoundsHistory(gameData);
      setCurrentOdd({ current_odd: crashValue });
      setStartTime(0);
    }
  }, [isStarted, isCrashed, activeOptions.ball]);

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'visible') {
        const date = new Date().getTime();
        const diff = (launchTime - date) / 1000 + 0.7;
        setStartTime(diff);
      } else {
        setStartTime(0);
      }
    };
    document.addEventListener('visibilitychange', listener);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener('visibilitychange', listener);
  }, [launchTime]);
  return (
    <div
      onClick={() => setHoverMusic(true)}
      className={`crazy-rocket ${styles}`}
      style={{ transform: `scale(${scale})`, '--animationTime': `${startTime}s` }}>
      <LeftSide toggleHandler={toggleHandler} />
      <Main
        toggleHandler={toggleHandler}
        activePopups={activePopups}
        roundId={roundId}
        defaultOptions={activeOptions}
        startTime={startTime}
        setStartTime={setStartTime}
      />
      <div>
        {iFrameMusicIsActive && rocketBrowser === 'isChrome' && hoverMusic && (
          <audio id="rocket-music-audio-element" autoPlay loop>
            <source src={sounds.rocketMusic} type="audio/mp3" />
          </audio>
        )}
        {iFrameMusicIsActive && !(rocketBrowser === 'isChrome') && (
          <audio id="rocket-music-audio-element" autoPlay loop>
            <source src={sounds.rocketMusic} type="audio/mp3" />
          </audio>
        )}

        {isSoundsOn && (
          <div>
            <audio className="rocket-start-audio-element">
              <source src={sounds.rocketStart} />
            </audio>
            <audio className="rocket-crash-audio-element">
              <source src={sounds.rocketCrash} />
            </audio>
            <audio className="rocket-bet-audio-element">
              <source src={sounds.rocketBet} />
            </audio>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  gameStateReducer,
  roundInfoReducer,
  randomSeedReducer,
  errorsReducer,
  roundsHistoryReducer,
  betResponseReducer,
  soundDataReducer,
  notificationsReducer,
  activeOptionsReducer,
}) => ({
  roundInfo: roundInfoReducer,
  gameState: gameStateReducer,
  randomSeedReducer,
  roundsHistory: roundsHistoryReducer,
  betResponseReducer,
  soundDataReducer,
  errorsReducer,
  notificationsReducer,
  activeOptions: activeOptionsReducer,
});

const mapDispatchToProps = {
  setCurrentOdd: setOdd,
  setRandomSeed,
  setIsActiveManualSeed,
  setBetData,
  setResetGameBets,
  setRoundsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
