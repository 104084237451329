import one from './1.png';
import two from './2.png';
import three from './3.png';
import four from './4.png';
import five from './5.png';
import six from './6.png';

const avatars = {
  1: one,
  2: two,
  3: three,
  4: four,
  5: five,
  6: six,
};
export default avatars;
