import React from 'react';
import { connect } from 'react-redux';
import LeaderHeader from './LeaderHeader';
import LeaderBody from './LeaderBody';

function Leaders({ setToolTip, toggleHandler, leadersListReducer, userInfoReducer }) {
  return (
    <li className={'menu-types__item leaders active'}>
      <LeaderHeader />
      <div className="leaders__list">
        {leadersListReducer.map((element, index) => (
          <LeaderBody
            key={element.id}
            index={index}
            data={element}
            setToolTip={setToolTip}
            toggleHandler={toggleHandler}
            userInfoReducer={userInfoReducer}
          />
        ))}
      </div>
    </li>
  );
}

const mapStateToProps = ({ leadersListReducer, userInfoReducer }) => ({
  leadersListReducer,
  userInfoReducer,
});

export default connect(mapStateToProps)(Leaders);
