import React, { useContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getActiveLanguage from '../assets/languages';
import { getLanguageFromUrl } from '../helpers/general';
import { setActiveLanguage } from '../redux/actions/gameActions';

const LanguageContext = React.createContext({});

const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const language = getLanguageFromUrl();
  const languageData = getActiveLanguage(language);

  useEffect(() => {
    dispatch(setActiveLanguage(language));
  }, []);

  const t = useCallback((text) => languageData[text] || text, [languageData]);
  return <LanguageContext.Provider value={t}>{children}</LanguageContext.Provider>;
};

export const useTranslation = () => useContext(LanguageContext);

export default LanguageProvider;
