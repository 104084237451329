import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserInfo from './UserInfo';
import FirstConsole from './FirstConsole';
import SecondConsole from './SecondConsole';
import { CONSOLE_TYPES } from '../../../../constants/game';
import { setNotifications } from '../../../../redux/actions/gameActions';

const { FIRST, SECOND } = CONSOLE_TYPES;

const Console = ({
  popupToggleHandler,
  currentMyBets,
  userInfo,
  startTime,
  gameState,
  setStartTime,
  // eslint-disable-next-line no-shadow
  notificationsReducer,
  setNotifications,
}) => {
  const [isSecondTicketActive, setSecondTicketActive] = useState(false);
  const [canAddTicket, setCanAddTicket] = useState(true);
  const { is_started: isStarted } = gameState;
  const toggleHandler = () => {
    setSecondTicketActive(!isSecondTicketActive);
  };

  useEffect(() => {
    if (currentMyBets.length) {
      (currentMyBets.length > 1 || currentMyBets[0].c === SECOND) && setSecondTicketActive(true);
    }
  }, [currentMyBets]);

  useEffect(() => {
    let intervalId;
    if (startTime > 1) {
      let restTime = startTime;
      intervalId = setInterval(() => {
        restTime -= 0.1;
        if (restTime <= 1) {
          clearInterval(intervalId);
          setCanAddTicket(false);
        } else if (isStarted === true) {
          setCanAddTicket(true);
          clearInterval(intervalId);
          setStartTime(0);
        }
      }, 100);
    }

    return () => clearInterval(intervalId);
  }, [startTime, isStarted]);

  useEffect(() => {
    isStarted === true && setCanAddTicket(true);
  }, [isStarted]);
  return (
    <div className="console__field">
      <FirstConsole
        isSecondTicketActive={isSecondTicketActive}
        toggleHandler={toggleHandler}
        userInfo={userInfo}
        canAddTicket={canAddTicket}
        noteData={notificationsReducer[FIRST]}
        setNotifications={setNotifications}
      />
      {isSecondTicketActive && (
        <SecondConsole
          toggleHandler={toggleHandler}
          userInfo={userInfo}
          canAddTicket={canAddTicket}
          noteData={notificationsReducer[SECOND]}
          setNotifications={setNotifications}
        />
      )}
      <UserInfo popupToggleHandler={popupToggleHandler} />
    </div>
  );
};

const mapStateToProps = ({
  currentMyBetsReducer,
  userInfoReducer,
  gameStateReducer,
  notificationsReducer,
}) => ({
  currentMyBets: currentMyBetsReducer,
  userInfo: userInfoReducer,
  gameState: gameStateReducer,
  notificationsReducer,
});

const mapDispatchToProps = {
  setNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Console);
