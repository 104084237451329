import React from 'react';
import { connect } from 'react-redux';
import PreviousGame from './PreviousGame/PreviousGame';
import GameHeader from './GameHeader';
import GameItem from './GameItem';
import { generateOddColor } from '../../../../helpers/generateOddColor';

const Game = ({ gameBetsReducer, previousGameReducer }) => {
  const gamersCount = Object.keys(gameBetsReducer).length;
  const sortingBets = (Object.values(gameBetsReducer)).sort((a, b) => {
    if (a.amount < b.amount) {
      return 1;
    }
    if (a.amount > b.amount) {
      return -1;
    }
    return 0;
  });
  return (
    <li className={'menu-types__item game active'}>

      <GameHeader gamersCount={gamersCount} />

      <div className='game__list'>
        {
          sortingBets.map((item, index) => {
            // eslint-disable-next-line camelcase
            const { cashout_odd, id } = item;
            const number = generateOddColor(cashout_odd) || 0;
            return <GameItem item={item} number={number} key={index} />;
          })
        }
      </div>
      <PreviousGame data={previousGameReducer} />
    </li>
  );
};

const mapStateToProps = ({ gameBetsReducer, previousGameReducer }) => ({ gameBetsReducer, previousGameReducer });

export default connect(mapStateToProps)(Game);
