import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MyBets from './MyBets/MyBets';
import Leaders from './Leaders/Leaders';
import Game from './Game/Game';
import { useTranslation } from '../../../context/LanguageProvider';

LeftTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
};
function LeftTabs({ activeTab, toggleHandler, setToolTip, toolTip }) {
  const handleToggleClick = useCallback(toggleHandler, []);
  const handleToolTipActivate = useCallback(setToolTip, []);
  const t = useTranslation();

  return (
    <div className="menu-types-wrapper">
      <div className="menu-types">
        <ul className="menu-types__list">
          {activeTab === 0 && (
            <MyBets setToolTip={handleToolTipActivate} toggleHandler={handleToggleClick} />
          )}
          {activeTab === 1 && <Game />}
          {activeTab === 2 && (
            <Leaders setToolTip={handleToolTipActivate} toggleHandler={handleToggleClick} />
          )}
        </ul>
      </div>
      {toolTip.coord && (
        <div className="tooltip tooltip--active" style={{ top: `${toolTip.coord}px` }}>
          <div className="tooltip__field">{t(toolTip.text)}</div>
        </div>
      )}
    </div>
  );
}

export default LeftTabs;
