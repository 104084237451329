import React from 'react';
import avatars from '../../../../../../assets/img/avatars/avatars';
import { useTranslation } from '../../../../../../context/LanguageProvider';

// avatar: 1
// created_at: null
// id: 5215
// round_id: 4978
// seed: "UHfk9mTDmGvIuGVbU49q"
// seeder: "+***6"
// seeder_id: 291790
// updated_at: null

const CurrentSeed = ({ gamer, number }) => {
  const { avatar, seeder, seed } = gamer;
  const t = useTranslation();
  return (
    <div className="popup-probably__column">
      <div className="popup-probably__column-gamer">
        {t('GAMER')} {number}
      </div>
      {seeder ? (
        <>
          <div className="popup-probably__column-user">
            <div className="game__gamers ">
              <div className="game__gamers-photo">
                <img className="avatars-img" src={avatars[avatar]} alt="avatar" />
              </div>
              <div className="game__gamers-number ">{seeder}</div>
            </div>
          </div>
          <div className="popup-probably__column-seed">{t('SEED')}</div>
          <div className="popup-probably__column-hash">{seed}</div>
        </>
      ) : (
        <div className="popup-probably__column-user">
          <div className="game__gamers ">
            <div className="game__gamers-photo" />
            <div className="game__gamers-number ">{t('Waiting for Player...')}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CurrentSeed;
