import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import MessageItem from './MessageItem';
import MessageShare from './MessageShare';
// eslint-disable-next-line import/no-cycle
import ChatInput from './ChatInput';
// eslint-disable-next-line import/no-cycle
import ChatGIF from './ChatGIF';
import GIF from './GIF';
import CaptainBot from './CaptainBot';

function ChatBody({
  avatar, toolTip, setToolTip, toggleHandler, isOpenChat, userId, chatHistoryReducer: data, currentRoundID,
}) {
  const [ writeGIF, setWriteGIF ] = useState(false);
  const [ emojiHandle, setEmojiHandle ] = useState(false);
  const [ rateLimit, setRateLimit ] = useState(true);
  const [ message, setMessage ] = useState('');
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (isOpenChat) {
      messagesEndRef.current.scroll({
        top: 100000,
        behavior: 'smooth',
      });
    }
  }, [ isOpenChat, data.length ]);

  const outSideClick = (evt) => {
    const flyOutElement = document.querySelector('.chat__body');
    let targetElement = evt.target;
    do {
      if (targetElement === flyOutElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    setWriteGIF(false);
    setEmojiHandle(false);
  };

  useEffect(() => {
    document.addEventListener('click', outSideClick);
    return () => {
      document.removeEventListener('click', outSideClick);
    };
  }, [ outSideClick ]);

  const onEmojiClick = (event) => {
    (message + event.native).length <= 160 && setMessage(prev => prev + event.native);
  };

  return (
    <div className='chat__body'>
      <div ref={messagesEndRef} className='message__list'>
        {
          data.map((item, index) => {
            const { id, type } = item;
            return (
              // eslint-disable-next-line no-nested-ternary
              type === 0
                ? <MessageItem
                  userId={userId}
                  key={index}
                  data={item}
                />
                // eslint-disable-next-line no-nested-ternary
                : type === 1
                  ? <MessageShare
                    userId={userId}
                    toolTip={toolTip}
                    setToolTip={setToolTip}
                    key={index}
                    data={item}
                    toggleHandler={toggleHandler}
                    currentRoundID={currentRoundID}
                />
                // eslint-disable-next-line no-nested-ternary
                  : type === 2
                    ? <GIF
                      userId={userId}
                      key={index}
                      data={item}
                  />
                  // eslint-disable-next-line no-nested-ternary
                    : type === 3
                      ? <CaptainBot key={index} />
                      : null
            );
          })
        }
      </div>
      {writeGIF
      && <ChatGIF
        writeGIF={writeGIF}
        setWriteGIF={setWriteGIF}
        avatar={avatar}
        rateLimit={rateLimit}
        setRateLimit={setRateLimit}
      />}
      { emojiHandle
      && <Picker
        onClick={onEmojiClick}
        include={[ 'people' ]}
      /> }
      <ChatInput
        emojiHandle={emojiHandle}
        setEmojiHandle={setEmojiHandle}
        writeGIF={writeGIF}
        setWriteGIF={setWriteGIF}
        avatar={avatar}
        message={message}
        setMessage={setMessage}
        rateLimit={rateLimit}
        setRateLimit={setRateLimit}
        isOpenChat={isOpenChat}
      />
    </div>
  );
}

const mapStateToProps = ({ chatHistoryReducer }) => ({ chatHistoryReducer });

export default connect(mapStateToProps)(ChatBody);
