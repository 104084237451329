export const pushToStorage = function (key, value) {
  const sentData = {
    set: { key, value },
  };
  window.top.postMessage(JSON.stringify(sentData), '*');
};

export const getFromStorage = function (key) {
  const sentData = {
    get: key,
  };
  window.top.postMessage(JSON.stringify(sentData), '*');
};
