// eslint-disable-next-line import/named
import { dataTypes, betDataTypes, serverSeedType } from '../actionTypes';

const {
  SET_BET_DATA, SET_BET_RESPONSE, SET_CURRENT_MY_BETS, RESET_BET_RESPONSE, FILL_BET_RESPONSE, UPDATE_BET_RESPONSE,
  SET_CASH_OUT_DATA, RESET_CASH_OUT_DATA, UPDATE_CASH_OUT_DATA,
} = betDataTypes;

const {
  SET_ROUND_INFO, SET_ODD, SET_GAME_BETS, RESET_GAME_BETS,
} = dataTypes;

const { SET_SERVER_SEED } = serverSeedType;

export const setRoundInfo = value => ({ type: SET_ROUND_INFO, value });

export const setOdd = ({ current_odd: currentOdd }) => ({ type: SET_ODD, value: currentOdd });

export const setBetData = value => ({ type: SET_BET_DATA, value });

export const setBetResponse = value => ({ type: SET_BET_RESPONSE, value });

export const resetBetResponse = () => ({ type: RESET_BET_RESPONSE });

export const setGameBets = value => ({ type: SET_GAME_BETS, value });

export const setResetGameBets = value => ({ type: RESET_GAME_BETS, value });

export const setCurrentMyBets = value => ({ type: SET_CURRENT_MY_BETS, value });

export const updateBetResponse = value => ({ type: UPDATE_BET_RESPONSE, value });

export const fillBetResponse = value => ({ type: FILL_BET_RESPONSE, value });

export const setCashOutData = value => ({ type: SET_CASH_OUT_DATA, value });

export const resetCashOutData = () => ({ type: RESET_CASH_OUT_DATA });

export const updateCashOutData = value => ({ type: UPDATE_CASH_OUT_DATA, value });

export const setServerSeed = value => ({ type: SET_SERVER_SEED, value });