import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const OddButtons = ({ handleAddAmount }) => {
  const [amountRange, setAmountRange] = useState([]);
  const userInfo = useSelector((state) => state.userInfoReducer);
  const amountRanges = userInfo.amountRanges;
  const oddKeys = ['first', 'second', 'third'];

  useEffect(() => {
    amountRanges &&
      amountRanges.map((item, index) => {
        const oddButton = Array.from(String(item)).map(Number);
        setAmountRange((prev) => [...prev, [oddKeys[index], oddButton]]);
      });
  }, [amountRanges]);

  return (
    <div className="console__buttons-bottom">
      {amountRange.map((item, ind) => {
        const [key, numbers] = item;
        const value = numbers.reduce((prev, current) => prev.toString() + current);

        const otherStyles = () => {
          if (numbers.length === 1) {
            return `${key}-1`;
          } else if (numbers.length === 2) {
            return `${key}-2`;
          } else if (numbers.length === 3) {
            return `${key}-3`;
          } else if (numbers.length === 4) {
            return `${key}-4`;
          }
        };

        return (
          <button
            type="button"
            key={key + ind}
            className={`odd-button odd-button-${otherStyles()}`}
            onClick={() => handleAddAmount(+value)}
            onKeyPress={(e) => e.preventDefault()}>
            {numbers.map((number, index) => {
              const current = index + 1;
              return (
                <span
                  key={key + index}
                  style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
                  className={`odd-button-${key + current}`}>
                  {number}
                </span>
              );
            })}
          </button>
        );
      })}
    </div>
  );
};

export default OddButtons;
