import React, { useEffect, useState } from 'react';
import { POPUPS, SVGIMG } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
import images from '../../../../assets/img/index';
import { setChatLike } from '../../../../Home';
import { useTranslation } from '../../../../context/LanguageProvider';

function MessageShare({ data, toggleHandler, userId, currentRoundID }) {
  const {
    amount,
    avatar,
    currency,
    cashOutAmount,
    cashOutOdd,
    nickname,
    userAvatar,
    roundId,
    likes,
    id,
    userNickname,
  } = data;

  const [isLiked, setIsLiked] = useState(false);

  const [lengthLike, setLengthLike] = useState(0);

  const likeHandle = () => {
    setChatLike({ id });
  };

  useEffect(() => {
    setLengthLike(likes.length);
    const arg = likes.includes(userId);
    setIsLiked(arg);
  }, [data.likes.length, userId]);

  const t = useTranslation();

  return (
    <div className="message__share">
      <div className="message__share-top">
        <div className="message__share-bot-left">
          <div className="message__user-photo">
            <img src={avatars[userAvatar]} alt="avatar" className="avatars-img" />
          </div>
        </div>
        <div className="message__share-bot-center">
          <div className="message__share-bot-title">
            <div className="message__share-bot-title-left">{userNickname}</div>
          </div>
        </div>
        <div className="message__share-bot-right">
          <button
            onClick={likeHandle}
            type="button"
            className={`message__like ${isLiked && 'active'}`}
            tabIndex="-1">
            <span className="message__like-icon">{SVGIMG.chatLike}</span>
            <span className="message__like-count">{lengthLike}</span>
          </button>
        </div>
      </div>
      <div className="message__share-bottom">
        <div className="message__share-user">
          <div className="message__user-photo">
            <img src={avatars[avatar]} alt="avatar" className="avatars-img" />
          </div>
          <div className="message__share-user-name">{nickname}</div>
          {roundId !== currentRoundID && (
            <div className="message__share-user-validity">
              <span className="validity__link-icon">
                <img
                  src={images.leaderValidity}
                  alt="Validity Icon"
                  onClick={() => toggleHandler(POPUPS.PROBABLY, roundId)}
                  className="validity-icon-svg"
                />
              </span>
            </div>
          )}
        </div>
        <div className="message__share-bet">
          <div className="message__share-bet-left message__share-bet-left--width">{t('BET')}</div>
          <div className="message__share-bet-center message__share-bet-center--width">X</div>
          <div className="message__share-bet-right">{t('WIN')}</div>
        </div>
        <div className="message__share-odd">
          <div className="message__share-odd-left message__share-bet-left--width">
            {amount} {currency}
          </div>
          <div className="message__share-odd-center message__share-bet-center--width">
            <span className="message__odd wcolor-50">{cashOutOdd}</span>
          </div>
          <div className="message__share-bet-right">
            {cashOutAmount} {currency}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageShare;
