/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import BetHeader from './BetHeader';
// eslint-disable-next-line import/no-cycle
import BetBody from './BetBody';

function MyBetByID({
  data, setToolTip, toggleHandler, userInfoReducer, prevRoundID,
}) {
  const {
    amount, cashout_amount, cashout_odd, created_at, id, is_won, round_id, currency, crash_value,
  } = data;
  const avatar = 3;
  const nickname = 3;
  return (
    <div
      className={`${round_id !== prevRoundID ? 'my-bets__item' : 'my-bets__item-double'} ${crash_value ? '' : 'my-bets__item--main'}`}
    >
      {round_id !== prevRoundID
            && <BetHeader
              createdAt={created_at}
              setToolTip={setToolTip}
              toggleHandler={toggleHandler}
              roundId={round_id}
              cashoutOdd={cashout_odd}
              crashValue={crash_value}
        />
        }
      <BetBody
        cashOutOdd={cashout_odd}
        cashOutAmount={cashout_amount}
        setToolTip={setToolTip}
        amount={amount}
        currency={currency}
        isWon={is_won}
        crashValue={crash_value}
        avatar={avatar}
        nickname={nickname}
        userInfoReducer={userInfoReducer}
        roundId={round_id}
      />
    </div>
  );
}

export default MyBetByID;
