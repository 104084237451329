import React, { useState } from 'react';
import { connect } from 'react-redux';
import { SVGIMG } from '../../../constants/game';
import { setIsActiveManualSeed } from '../../../redux/actions/gameActions';
import { pushToStorage } from '../../../helpers/storageMenagement';
import { useTranslation } from '../../../context/LanguageProvider';

// eslint-disable-next-line no-shadow
function RandomInput({ isActive, randomSeedReducer, setIsActiveManualSeed }) {
  let size;
  const [hoverToolTip, setHoverToolTip] = useState(false);
  const t = useTranslation();

  const clickTypeHandle = () => {
    pushToStorage('seedType', 'random');
    setIsActiveManualSeed(false);
  };

  return (
    <div className="settings__hash-item">
      <label className="settings__hash-label" onClick={clickTypeHandle}>
        <input
          type="radio"
          name="radio"
          className="settings__hash-input"
          defaultChecked={!isActive}
        />
        <span className="settings__hash-radio" />
        <span className="settings__hash-radio-text">{t('Random on every New Game')}</span>
      </label>
      <div className="settings__hash-bottom">
        <label className="settings__hash-bottom-text">
          <span className="settings__hash-current">{t('Current')}</span>
          <input
            id="randomSeedInput"
            className="settings__hash-code"
            readOnly
            value={randomSeedReducer}
          />
          <button
            type="button"
            className="settings__hash-copy"
            ref={(el) => {
              if (!el) return;
              size = el.getBoundingClientRect().top - 35;
            }}
            onClick={seedCopy}
            onMouseEnter={() => setHoverToolTip({ coord: size, text: 'Copy' })}
            onMouseLeave={() => setHoverToolTip({ coord: false })}>
            {SVGIMG[2]}
          </button>
          {hoverToolTip.coord && (
            <div
              className="hoverTooltip hoverTooltip--active"
              style={{ top: `${hoverToolTip.coord}px` }}>
              <div className="hoverTooltip__field">{t(hoverToolTip.text)}</div>
            </div>
          )}
        </label>
      </div>
    </div>
  );

  function seedCopy() {
    setHoverToolTip({ coord: size, text: 'The seed is copied' });
    const copyText = document.getElementById('randomSeedInput');
    copyText.select();
    document.execCommand('copy');
  }
}

const mapStateToProps = ({ gameStateReducer, isActiveManualSeedReducer, randomSeedReducer }) => ({
  gameStateReducer,
  isActive: isActiveManualSeedReducer,
  randomSeedReducer,
});

const mapDispatchToProps = {
  setIsActiveManualSeed,
};

export default connect(mapStateToProps, mapDispatchToProps)(RandomInput);
