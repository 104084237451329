import React, { useState, useEffect } from 'react';
import { SVGIMG } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
import { setChatLike } from '../../../../Home';

function GIF({ data, userId }) {
  const {
    avatar, content, id, likes, username,
  } = data;
  const [ isLiked, setIsLiked ] = useState(false);

  const [ lengthLike, setLengthLike ] = useState(0);

  const likeHandle = () => {
    setChatLike({ id });
  };

  useEffect(() => {
    setLengthLike(likes.length);
    const arg = likes.includes(userId);
    setIsLiked(arg);
  }, [ data.likes.length, userId ]);

  return (
    <div className='message__item'>
      <div className='message__user-photo'>
        <img src={avatars[avatar]} alt='avatar' className='avatars-img' />
      </div>
      <div className='message__field'>
        <div className='message__user-name'>{username}</div>
        <div className='message__body__gif'>
          {/* <div style={{ width: '170px', height: '150px', position: 'absolute' }} /> */}
          <iframe
            className='message__body__iframe'
            src={`https://giphy.com/embed/${content}`}
            width='170'
            frameBorder='0'
              />

          <button
            onClick={likeHandle}
            type='button'
            className={`message__like ${isLiked && 'active'}`}
            tabIndex='-1'
          >
            <span className='message__like-icon'>
              {SVGIMG.chatLike}
            </span>
            <span className='message__like-count'>{lengthLike}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default GIF;
