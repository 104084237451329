import React from 'react';
import PropTypes from 'prop-types';
import SettingsBody from './SettingsBody';

BurgerMenu.propTypes = {
  isActive: PropTypes.bool.isRequired,
  activeBurgerTab: PropTypes.number.isRequired,
  handleIsActive: PropTypes.func.isRequired,
  handleActiveBurgerTab: PropTypes.func.isRequired,
};

function BurgerMenu({
  isActive, handleIsActive, handleActiveBurgerTab, activeBurgerTab,
}) {
  return (
    <div className={`settings__field ${isActive ? 'active' : ''}`}>
      <button
        type='button'
        className='settings settings-burger '
        onClick={() => handleIsActive()}>
        <span className='burger-first' />
        <span className='burger-second' />
        <span className='burger-third' />
      </button>

      {isActive && <SettingsBody
        isActive={isActive}
        handleIsActive={handleIsActive}
        handleActiveBurgerTab={handleActiveBurgerTab}
        activeBurgerTab={activeBurgerTab} />}
    </div>
  );
}

export default BurgerMenu;
