import React, { useEffect, useState } from 'react';
import { SVGIMG } from '../../../../constants/game';
import avatars from '../../../../assets/img/avatars/avatars';
import images from '../../../../assets/img/index';
import { useTranslation } from '../../../../context/LanguageProvider';

function CaptainBot({ data, userId }) {
  const { avatar, content, id, likes, username } = data;
  const [isLiked, setIsLiked] = useState(false);

  const [lengthLike, setLengthLike] = useState('');

  useEffect(() => {
    setIsLiked(likes.includes(userId));
    setLengthLike(likes.length);
  }, [data, userId]);

  const t = useTranslation();

  const nickname = 'M***95';
  const wish = `${t('Best in this Round')}`;
  const congrats = `${t('With HUGE WIN!')}`;
  const currency = '$';
  const amount = 150;
  const cashOutOdd = '8.0x';
  const win = 1200;

  return (
    <div className="message__share">
      <div className="message__share-top">
        <div className="message__share-bot-left">
          <div className="message__user-photo message__user-photo--bot">B</div>
        </div>
        <div className="message__share-bot-center">
          <div className="message__share-bot-title">
            <div className="message__share-bot-title-left">{t('Captain BOT')}</div>
            <div className="message__share-bot-title-right">{wish}</div>
          </div>
          <div className="message__share-bot-title">
            <div className="message__share-bot-win-left">{congrats}</div>
            <div className="message__share-bot-win-right">{nickname}</div>
          </div>
        </div>
        <div className="message__share-bot-right">
          <button type="button" className={`message__like ${isLiked && 'active'}`} tabIndex="-1">
            <span className="message__like-icon">{SVGIMG.chatLike}</span>
            <span className="message__like-count">{lengthLike}</span>
          </button>
        </div>
      </div>
      <div className="message__share-bottom">
        <div className="message__share-user">
          <div className="message__user-photo">
            <img src={avatars[1]} alt="avatar" className="avatars-img" />
          </div>
          <div className="message__share-user-name">{nickname}</div>
          <div className="message__share-user-validity">
            <span className="validity__link-icon">
              <img src={images.leaderValidity} alt="Validity Icon" className="validity-icon-svg" />
            </span>
          </div>
        </div>
        <div className="message__share-bet">
          <div className="message__share-bet-left message__share-bet-left--width">{t('BET')}</div>
          <div className="message__share-bet-center message__share-bet-center--width">X</div>
          <div className="message__share-bet-right">{t('WIN')}</div>
        </div>
        <div className="message__share-odd">
          <div className="message__share-odd-left message__share-bet-left--width">
            {currency}
            {amount}
          </div>
          <div className="message__share-odd-center message__share-bet-center--width">
            <span className="message__odd wcolor-50">{cashOutOdd}</span>
          </div>
          <div className="message__share-bet-right">
            {currency}
            {win}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaptainBot;
