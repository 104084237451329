import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import MyBetByID from './MyBetByID';

MyBets.propTypes = {
  setToolTip: PropTypes.func.isRequired,
};

function MyBets({
  setToolTip, toggleHandler, myBetsListReducer, userInfoReducer,
}) {
  return (
    <li className={'menu-types__item my-bets active'}>
      <div className='my-bets__list'>
        {myBetsListReducer.map((element, index) => <MyBetByID
          data={element}
          key={index}
          setToolTip={setToolTip}
          toggleHandler={toggleHandler}
          userInfoReducer={userInfoReducer}
          prevRoundID={myBetsListReducer[index - 1]?.round_id}
          />)}
      </div>
    </li>
  );
}

const mapStateToProps = ({
  myBetsListReducer, userInfoReducer,
}) => ({
  myBetsListReducer, userInfoReducer,
});

export default connect(mapStateToProps)(MyBets);
