import React from 'react';
import moment from 'moment';
import { generateOddColor } from '../../../../../helpers/generateOddColor';

const ProbablyHeader = ({ closePopup, roundData }) => {
  const { id, crash_value: crashValue, launch_time: launchTime } = roundData;
  const date = moment(+launchTime);
  const dateByDay = date.format('DD.MM.YY');
  const dateByHours = date.format('HH:mm:ss');
  const number = generateOddColor(crashValue);
  return (
    <div className="popup-probably__header">
      <div className="popup-probably__head">
        <div className="popup-probably__head-id popup-probably__head--border">ID: {id}</div>
        <div className="popup-probably__head-odd popup-probably__head--border">
          <span className={`message__odd wcolor-${number}`}>{crashValue}x</span>
        </div>
        <div className="popup-probably__head-date popup-probably__head--border">{dateByDay}</div>
        <div className="popup-probably__head-time">{dateByHours}</div>
      </div>
      <div className="popup-probably__close" onClick={closePopup}>
        <button className="popup-validity__close">
          <svg
            className="popup-validity__close-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14">
            <path d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ProbablyHeader;
