export const generateOddColor = (odd) => {
  let number;
  if (odd >= 1 && odd <= 2) {
    number = 1;
  } else if (odd > 2 && odd <= 3) {
    number = 2;
  } else if (odd > 3 && odd <= 4) {
    number = 3;
  } else if (odd > 4 && odd <= 5) {
    number = 4;
  } else if (odd > 5 && odd <= 6) {
    number = 5;
  } else if (odd > 6 && odd <= 7) {
    number = 6;
  } else if (odd > 7 && odd <= 8) {
    number = 7;
  } else if (odd > 8 && odd <= 9) {
    number = 8;
  } else if (odd > 9 && odd <= 10) {
    number = 9;
  } else if (odd > 10 && odd <= 11) {
    number = 10;
  } else if (odd > 11 && odd <= 12) {
    number = 11;
  } else if (odd > 12 && odd <= 13) {
    number = 12;
  } else if (odd > 13 && odd <= 14) {
    number = 13;
  } else if (odd > 14 && odd <= 15) {
    number = 14;
  } else if (odd > 15 && odd <= 16) {
    number = 15;
  } else if (odd > 16 && odd <= 17) {
    number = 16;
  } else if (odd > 17 && odd <= 18) {
    number = 17;
  } else if (odd > 18 && odd <= 19) {
    number = 18;
  } else if (odd > 19 && odd <= 20) {
    number = 19;
  } else if (odd > 20 && odd <= 21) {
    number = 20;
  } else if (odd > 21 && odd <= 22) {
    number = 21;
  } else if (odd > 22 && odd <= 23) {
    number = 22;
  } else if (odd > 23 && odd <= 24) {
    number = 23;
  } else if (odd > 24 && odd <= 25) {
    number = 24;
  } else if (odd > 25 && odd <= 26) {
    number = 25;
  } else if (odd > 26 && odd <= 27) {
    number = 26;
  } else if (odd > 27 && odd <= 28) {
    number = 27;
  } else if (odd > 28 && odd <= 29) {
    number = 28;
  } else if (odd > 29 && odd <= 30) {
    number = 29;
  } else if (odd > 30 && odd <= 31) {
    number = 30;
  } else if (odd > 31 && odd <= 32) {
    number = 31;
  } else if (odd > 32 && odd <= 33) {
    number = 32;
  } else if (odd > 33 && odd <= 34) {
    number = 33;
  } else if (odd > 34 && odd <= 35) {
    number = 34;
  } else if (odd > 35 && odd <= 36) {
    number = 35;
  } else if (odd > 36 && odd <= 37) {
    number = 36;
  } else if (odd > 37 && odd <= 38) {
    number = 37;
  } else if (odd > 38 && odd <= 39) {
    number = 38;
  } else if (odd > 39 && odd <= 40) {
    number = 39;
  } else if (odd > 40 && odd <= 41) {
    number = 40;
  } else if (odd > 41 && odd <= 42) {
    number = 41;
  } else if (odd > 42 && odd <= 43) {
    number = 42;
  } else if (odd > 43 && odd <= 44) {
    number = 43;
  } else if (odd > 44 && odd <= 45) {
    number = 44;
  } else if (odd > 45 && odd <= 46) {
    number = 45;
  } else if (odd > 46 && odd <= 47) {
    number = 46;
  } else if (odd > 47 && odd <= 48) {
    number = 47;
  } else if (odd > 48 && odd <= 49) {
    number = 48;
  } else if (odd > 49 && odd <= 50) {
    number = 49;
  } else if (odd > 50 && odd <= 51) {
    number = 50;
  } else if (odd > 51 && odd <= 52) {
    number = 51;
  } else if (odd > 52 && odd <= 53) {
    number = 52;
  } else if (odd > 53 && odd <= 54) {
    number = 53;
  } else if (odd > 54 && odd <= 55) {
    number = 54;
  } else if (odd > 55 && odd <= 56) {
    number = 55;
  } else if (odd > 56 && odd <= 57) {
    number = 56;
  } else if (odd > 57 && odd <= 58) {
    number = 57;
  } else if (odd > 58 && odd <= 59) {
    number = 58;
  } else if (odd > 59 && odd <= 60) {
    number = 59;
  } else if (odd > 60 && odd <= 61) {
    number = 60;
  } else if (odd > 61 && odd <= 62) {
    number = 61;
  } else if (odd > 62 && odd <= 63) {
    number = 62;
  } else if (odd > 63 && odd <= 64) {
    number = 63;
  } else if (odd > 64 && odd <= 65) {
    number = 64;
  } else if (odd > 65 && odd <= 66) {
    number = 65;
  } else if (odd > 66 && odd <= 67) {
    number = 66;
  } else if (odd > 67 && odd <= 68) {
    number = 67;
  } else if (odd > 68 && odd <= 69) {
    number = 68;
  } else if (odd > 69 && odd <= 70) {
    number = 69;
  } else if (odd > 70 && odd <= 71) {
    number = 70;
  } else if (odd > 71 && odd <= 72) {
    number = 71;
  } else if (odd > 72 && odd <= 73) {
    number = 72;
  } else if (odd > 73 && odd <= 74) {
    number = 73;
  } else if (odd > 74 && odd <= 75) {
    number = 74;
  } else if (odd > 75 && odd <= 76) {
    number = 75;
  } else if (odd > 76 && odd <= 77) {
    number = 76;
  } else if (odd > 77 && odd <= 78) {
    number = 77;
  } else if (odd > 78 && odd <= 79) {
    number = 78;
  } else if (odd > 79 && odd <= 80) {
    number = 79;
  } else if (odd > 80 && odd <= 81) {
    number = 80;
  } else if (odd > 81 && odd <= 82) {
    number = 81;
  } else if (odd > 82 && odd <= 83) {
    number = 82;
  } else if (odd > 83 && odd <= 84) {
    number = 83;
  } else if (odd > 84 && odd <= 85) {
    number = 84;
  } else if (odd > 85 && odd <= 86) {
    number = 85;
  } else if (odd > 86 && odd <= 87) {
    number = 86;
  } else if (odd > 87 && odd <= 88) {
    number = 87;
  } else if (odd > 88 && odd <= 89) {
    number = 88;
  } else if (odd > 89 && odd <= 90) {
    number = 89;
  } else if (odd > 90 && odd <= 91) {
    number = 90;
  } else if (odd > 91 && odd <= 92) {
    number = 91;
  } else if (odd > 92 && odd <= 93) {
    number = 92;
  } else if (odd > 93 && odd <= 94) {
    number = 93;
  } else if (odd > 94 && odd <= 95) {
    number = 94;
  } else if (odd > 95 && odd <= 96) {
    number = 95;
  } else if (odd > 96 && odd <= 97) {
    number = 96;
  } else if (odd > 97 && odd <= 98) {
    number = 97;
  } else if (odd > 98 && odd <= 99) {
    number = 98;
  } else if (odd > 99 && odd <= 100) {
    number = 99;
  } else if (odd > 100) {
    number = 100;
  }
  return number;
};
