import React from 'react';
import { useTranslation } from '../../../../../context/LanguageProvider';

const ServerSeed = ({ serverSeed }) => {
  const t = useTranslation();
  return (
    <>
      <div className="settings__server">
        <div className="settings__server-left">
          <svg
            className="settings__server-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35.92 35.92">
            <path d="M0,0V10.57H35.92V0ZM4.21,6.34V4.23H6.32V6.34Zm4.21,0V4.23h2.11V6.34Zm14.17,0V4.23h9.12V6.34Z" />
            <path d="M0,23.24H35.92V12.68H0Zm31.71-6.33V19H22.59v-2.1Zm-21.18,0V19H8.42v-2.1Zm-4.21,0V19H4.21v-2.1Z" />
            <path d="M0,35.92H35.92V25.35H0Zm31.71-6.34v2.11H22.59V29.58Zm-21.18,0v2.11H8.42V29.58Zm-4.21,0v2.11H4.21V29.58Z" />
          </svg>
        </div>
        <div className="settings__server-right">
          <div className="settings__server-text--bold">{t('Server Seed')}</div>
          <div className="settings__server-text">{t('Generated on our side')}</div>
        </div>
      </div>
      <div className="settings__server-name">{serverSeed}</div>
    </>
  );
};
export default ServerSeed;
